<template>
  <b-row class="border-card h-100">
    <a class="col-4 p-0 img-link" :style="'border-color:' + colour" :href="link" target="_blank">
      <img class="background" :src="require('@/assets/MEDIAS/' + img)" />
      <div class="logo">
        <img :src="require('@/assets/Logos/' + DATASET.LOGOS[source])" />
      </div>
    </a>

    <div class="col-8">
      <b-row class="h-100">

        <div class="col-12 h-100">
          <div style="height: 60%;">
            <h1 class="pt-2 mt-1 pb-1" style="">
              {{ trim(title, 62) }}
            </h1>
          </div>

          <div style="height: 30%;">
            <b-row>
              <h5 class="col-5" style="display: flex; align-items: center;">{{ date }}</h5>
              <h5 class="col-7" style="text-align: right;"><a class="btn" :href="link"
                  target="_blank">{{ $t("more") }}</a>
              </h5>
            </b-row>
          </div>
        </div>
      </b-row>
    </div>
  </b-row>
</template>

<script>
import DATASET from './Dataset.js';

export default {
  name: "SmallArticleCard",
  props: ["title", "desc", "link", "img", "cat", "source", "date", "colour"],
  data() {
    return {
      DATASET
    }
  },
  methods: {
    trim(content, max) {
      content = content.trim();
      if (content.length > max)
        content = content.slice(0, max - 3) + "...";
      else content = content.padEnd(max, ' ');
      return content;
    }
  }
}
</script>


<style scoped>
.border-card {
  border-radius: 0px;
  box-shadow: 6px 4px 3px rgba(0, 0, 0, 0.062), 3px 0px 10px rgba(0, 0, 0, 0.111);
  border-radius: 6px;
  overflow: hidden;
}

.background {
  width: 100%;
  height: 90px;
  object-fit: cover;
  padding: 0;
}

.logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 90px;
  background: #000000af;
  transition: all ease-in-out .2s;
}

.logo:hover {
  background: #232323a1;
  cursor: pointer;
}

.logo:hover img {
  width: 65%;
  opacity: 1;
}

.logo>img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  transition: all ease-in-out .2s;
  opacity: 0.9;
}


.img-link {
  position: relative;
  height: 90px;
  overflow: hidden;
  border-bottom: solid 3px red;
}

h1 {
  font-size: 12px;
  text-align: left;
  font-weight: 700;
  margin: 0;
}

h5 {
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: grey;
  padding-bottom: 0;
  margin: 0;
}

p {
  font-size: 14px;
  color: gray;
  font-weight: 300;
}

a,
a:hover {
  text-decoration: none;
}

.btn {
  transition: all ease-in-out .2s;
  text-align: right;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: grey;
}

.btn:hover {
  /* color: black; */
  font-weight: 500;
}
</style>
