const DATA = [
  {
    "ID": "MEDIA024",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "ÉLECTROMAGNÉTISME: Vers Ia preuve scientifique d'un danger pour I'homme et I'animal ?",
    "TITLE-EN": "ELECTROMAGNETISM: Towards scientific proof of a danger to humans and animals?",
    "DESC-FR": "Une recente étude montrant un lien entre ondes et morts inexpliquées de bovins apporte de l'eau au moulin de Stéphane  Le Béchec, un éleveur d'Allineuc qui a perdu plus de 300 vaches en quatre ans.",
    "DESC-EN": "A recent study showing a link between wind turbines and unexplained cattle deaths has added weight to the case of Stéphane Le Béchec, a farmer in Allineuc who has lost more than 300 cows in four years.",
    "DATE": "08/01/2021",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/bretagne/loudeac_22136/electromagnetisme-vers-la-preuve-scientifique-d-un-danger-pour-l-homme-et-l-animal_38547635.html"
  },
  {
    "ID": "MEDIA057",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques dans l'Eure : du\nplomb et des terres rares trouvés dans\nles cheveux",
    "TITLE-EN": "Paediatric cancers in the Eure: lead and rare earths\nlead and rare earths found in\nhair",
    "DESC-FR": "Les résultats des tests capillaires effectués chez onze enfants ont révélé la\nprésence de terres rares et de plomb. Une piste pour chercher les causes\ndes cancers pédiatriques.",
    "DESC-EN": "The results of hair tests carried out on eleven children revealed the presence of rare earth elements and lead. This could be a clue to finding the causes of paediatric cancers.",
    "DATE": "10/06/2022",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/normandie/igoville_27348/cancers-pediatriques-dans-leure-du-plomb-et-des-terres-rares-trouves-dans-les-cheveux_51596966.html#:~:text=Igoville-,Cancers%20p%C3%A9diatriques%20dans%20l'Eure%20%3A%20du%20plomb%20et%20des%20terres,les%20causes%20des%20cancers%20p%C3%A9diatriques."
  },
  {
    "ID": "MEDIA050",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques à Pont-de-l'Arche les parents d'enfants malades créent leur association",
    "TITLE-EN": "Paediatric cancers in Pont-de-l'Arche: parents of sick children set up their own association",
    "DESC-FR": "Les parents d'enfants victimes de cancers ont créé une association afin de financer des analyses et rechercher les causes des maladies.",
    "DESC-EN": "The parents of children who have suffered from cancer have set up an association to fund analyses and research into the causes of the disease.",
    "DATE": "05/05/2022",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/normandie/pont-de-l-arche_27469/a-pont-de-larche-les-parents-denfants-malades-creent-leur-association_50742610.html"
  },
  {
    "ID": "MEDIA052",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cluster anormal de cancers d'enfants dans l'Eure : des tests effectués par les parents",
    "TITLE-EN": "Abnormal cluster of childhood cancers in Eure: tests carried out by parents",
    "DESC-FR": "Cluster anormal de cancers d'enfants\ndans l'Eure : des tests effectués par les\nparents",
    "DESC-EN": "Abnormal cluster of childhood cancers in Eure: tests carried out by the parents",
    "DATE": "20/03/2022",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/normandie/pont-de-l-arche_27469/video-cluster-anormal-de-cancers-d-enfants-dans-l-eure-des-tests-effectues-par-les-parents_49551103.html"
  },
  {
    "ID": "MEDIA018",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Incendie de Lubrizol à Rouen : une campagne d'analyse de cheveux menée auprès de 100 enfants",
    "TITLE-EN": "Lubrizol fire in Rouen: a campaign to analyse the hair of 100 children",
    "DESC-FR": "L'Association des sinistrés de Lubrizol a lancé en février 2022 une campagne de prélèvement de cheveux. Objectif : analyser les toxicités absorbées depuis l'incendie de l'usine.",
    "DESC-EN": "In February 2022, the Association des sinistrés de Lubrizol launched a campaign to take hair samples. The aim is to analyse the toxic substances absorbed since the factory fire.",
    "DATE": "14/02/2022",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/normandie/rouen_76540/incendie-de-lubrizol-a-rouen-une-campagne-d-analyse-de-cheveux-menee-aupres-de-100-enfants_48634901.html"
  },
  {
    "ID": "MEDIA020",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Seine-Maritime. Des prélèvements de cheveux menés sur des enfants pour établir un suivi sanitaire",
    "TITLE-EN": "Seine-Maritime. Hair samples taken from children to monitor their health",
    "DESC-FR": "L'association des sinistrés de Lubrizol a mené une campagne de prélèvements de cheveux sur des enfants le 26 mars 2022, à Rouen et Serqueux (Seine-Maritime).",
    "DESC-EN": "The Lubrizol victims' association carried out a campaign to take hair samples from children on 26 March 2022, in Rouen and Serqueux (Seine-Maritime).",
    "DATE": "28/03/2021",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/normandie/rouen_76540/seine-maritime-des-prelevements-de-cheveux-menes-sur-des-enfants-pour-etablir-un-suivi-sanitaire_49749539.html"
  },
  {
    "ID": "MEDIA041",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Aude. Deux ans après les inondations, des habitants testés révèlent la trace de 49 polluants toxiques",
    "TITLE-EN": "Aude. Two years after the floods, residents tested reveal traces of 49 toxic pollutants",
    "DESC-FR": "Après des tests sur 102 habitants, trois associations révèlent la présence de 49 éléments toxiques, pile deux ans après les inondations meurtrières de l'Aude. Explications.",
    "DESC-EN": "Following tests on 102 residents, three associations have revealed the presence of 49 toxic elements, exactly two years after the deadly floods in the Aude region. Here are the details.",
    "DATE": "20/10/2020",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/occitanie/trebes_11397/aude-deux-ans-apres-les-inondations-des-habitants-testes-revelent-la-trace-de-49-polluants-toxiques_36889009.html"
  },
  {
    "ID": "MEDIA010",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques en Loire-Atlantique : un enfant de 4 ans atteint de leucémie, à Machecoul-Saint-Même",
    "TITLE-EN": "Paediatric cancers in Loire-Atlantique: a 4-year-old child with leukaemia in Machecoul-Saint-Même",
    "DESC-FR": "Le collectif Stop aux cancers de nos enfants déplore un nouveau cas de\ncancer pédiatrique, chez un enfant âgé de 4 ans, à\nMachecoul-Saint-Même.",
    "DESC-EN": "The Stop Our Children's Cancers Collective deplores a new case of paediatric cancer in a young child.\nCancer in a 4-year-old child in Machecoul-Saint-Même.\nMachecoul-Saint-Même.",
    "DATE": "16/09/2020",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/pays-de-la-loire/machecoul-saint-meme_44087/cancers-pediatriques-en-loire-atlantique-un-enfant-de-4-ans-atteint-de-leucemie-a-machecoul-saint-meme_36166763.html"
  },
  {
    "ID": "MEDIA022",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Champs électromagnétiques et terres rares dans les exploitations de vaches laitières : attention danger ?",
    "TITLE-EN": "Electromagnetic fields and rare earths on dairy farms: danger warning?",
    "DESC-FR": "Champs électromagnétiques et des terres rares mêlés pourraient conduire à des pathologies graves chez les bovins, voire chez les humains. C’est la conclusion de ToxSeek urgence.",
    "DESC-EN": "The combination of electromagnetic fields and rare earths could lead to serious pathologies in cattle and even humans. This is the conclusion of ToxSeek urgence.",
    "DATE": "13/12/2021",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/pays-de-la-loire/nozay_44113/champs-electromagnetiques-et-terres-rares-dans-les-exploitations-de-vaches-laitieres-attention-danger%E2%80%89_47114554.html"
  },
  {
    "ID": "MEDIA025",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Vaches mortes et éleveurs malades à Nozay : une contamination aux métaux révélée",
    "TITLE-EN": "Dead cows and sick farmers in Nozay: metal contamination revealed",
    "DESC-FR": "Nouveau rebondissement dans l'affaire des vaches mortes et des éleveurs\nmalades à Nozay (Loire-Atlantique). Des analyses biologiques ont révélé\nune contamination aux terres rares.",
    "DESC-EN": "A new twist in the case of the dead cows and sick farmers in Nozay in Nozay (Loire-Atlantique). Biological analyses have revealed\n contamination by rare earths.",
    "DATE": "27/02/2020",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/pays-de-la-loire/nozay_44113/vaches-mortes-eleveurs-malades-nozay-une-contamination-metaux-revelee_31788801.html"
  },
  {
    "ID": "MEDIA011",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques en Loire-Atlantique : ce que dit l'analyse des cheveux des enfants",
    "TITLE-EN": "Paediatric cancers in Loire-Atlantique: what analysis of children's hair tells us",
    "DESC-FR": "L'association ToxSeek urgence a analysé les cheveux de 21 enfants, à\nSainte-Pazanne et dans ses environs. Une partie d'entre eux enregistre la\nprésence de métaux à fortes doses.",
    "DESC-EN": "The association ToxSeek urgence has analysed the hair of 21 children in and around Sainte-Pazanne and the surrounding area. Some of them show presence of metals in high doses.",
    "DATE": "30/09/2019",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/pays-de-la-loire/sainte-pazanne_44186/audio-cancers-pediatriques-loire-atlantique-ce-dit-lanalyse-cheveux-enfants_27746671.html"
  },
  {
    "ID": "MEDIA078",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercure, pesticides… Cet effrayant cocktail toxique retrouvé dans les cheveux de 26 sénateurs PS",
    "TITLE-EN": "Mercury, pesticides... This frightening toxic cocktail found in the hair of 26 PS senators",
    "DESC-FR": "Une analyse toxicologique réalisée sur les cheveux de sénateurs volontaires a livré des résultats effrayants. Tous sont contaminés à différents polluants organiques et métaux, sans doute à cause de l'utilisation importante d’objets électroniques connectés.",
    "DESC-EN": "A toxicological analysis carried out on the hair of volunteer senators has produced some frightening results. All were contaminated with various organic pollutants and metals, no doubt due to the extensive use of connected electronic devices.",
    "DATE": "27/06/2023",
    "SOURCE": "Orange",
    "LINK": "https://actu.orange.fr/politique/mercure-pesticides-cet-effrayant-cocktail-toxique-retrouve-dans-les-cheveux-de-26-senateurs-ps-magic-CNT00000255sQX.html"
  },
  {
    "ID": "MEDIA023",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "ÉOLIENNES ET SANTÉ : LA POLÉMIQUE",
    "TITLE-EN": "WIND TURBINES AND HEALTH: THE CONTROVERSY",
    "DESC-FR": "L'association SOS danger éolien se mobilise pour faire entendre la souffrance des habitants de l' Aisne cernés par les éoliennes. Ils seraient atteints du syndrome éolien, dénoncé par certains médecins, analyses à l'appui.",
    "DESC-EN": "The SOS danger éolien association is mobilising to highlight the suffering of people living in the Aisne region, surrounded by wind turbines. They are said to be suffering from wind farm syndrome, as denounced by a number of doctors and backed up by analyses.",
    "DATE": "27/01/2021",
    "SOURCE": "L'Union",
    "LINK": "https://press.toxseek.com/assets/2021-01-27%20-%20L'Union.pdf"
  },
  {
    "ID": "MEDIA001",
    "CAT": "Environnement",
    "FEATURED": true,
    "TYPE": "Presse",
    "TITLE-FR": "Pesticides, Nos cheveux révèlent notre contamination!",
    "TITLE-EN": "Pesticides, Our hair reveals our contamination!",
    "DESC-FR": "Des polluants à s’arracher les cheveux! Jusqu’à 28 substances problématiques dans l’organisme. C’est le résultat inquiétant de l’analyse des cheveux de 20 Suisses romands et alémaniques âgés de 2 à 77 ans. Même les tout-petits sont déjà contaminés par un cocktail de polluants.",
    "DESC-EN": "Hair-pulling pollutants! Up to 28 problematic substances in the body. These are the worrying results of hair analyses carried out on 20 Swiss people aged between 2 and 77. Even toddlers are already contaminated by a cocktail of pollutants.",
    "DATE": "08/05/2019",
    "SOURCE": "Bon à Savoir",
    "LINK": "https://press.toxseek.com/assets/FR.pdf"
  },
  {
    "ID": "MEDIA060",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "La sénatrice fait évaluer la présence\nde polluants dans ses cheveux",
    "TITLE-EN": "Senator has her hair assessed\nof pollutants in her hair.",
    "DESC-FR": "Angèle Préville, la sénatrice du Lot,\nest à l'initiative d'une grande opéra-\ntion d'analyse de plusieurs cheveux\nde sénateurs. L'objectif : évaluer l'ex-\nposition à la pollution.",
    "DESC-EN": "Angèle Préville, Senator for the Lot, is behind a major operation- analysis of several senators. The aim: to assess the exposition to pollution.",
    "DATE": "25/07/2022",
    "SOURCE": "La Dépêche",
    "LINK": "https://press.toxseek.com/assets/2022-07-25%20-%20La%20d%C3%A9p%C3%AAche.pdf"
  },
  {
    "ID": "MEDIA029",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pollution dans la Vallée de l’Arve : une \"contamination significative au cadmium\" retrouvée dans des cheveux d’enfants",
    "TITLE-EN": "Pollution in the Arve Valley: \"significant cadmium contamination\" found in children's hair",
    "DESC-FR": "Le collectif citoyen Coll’Air Pur a lancé en septembre dernier une campagne d’analyses de cheveux de 76 enfants résidants dans la vallée de l’Arve. Les résultats démontrent un fort taux de contamination au cadmium.",
    "DESC-EN": "Last September, the Coll'Air Pur citizens' collective launched a campaign to analyse the hair of 76 children living in the Arve Valley. The results showed a high level of cadmium contamination.",
    "DATE": "09/12/2020",
    "SOURCE": "France 3",
    "LINK": "https://france3-regions.francetvinfo.fr/auvergne-rhone-alpes/haute-savoie/chamonix/pollution-vallee-arve-contamination-significative-au-cadmium-retrouvee-cheveux-enfants-1903718.html"
  },
  {
    "ID": "MEDIA015",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancer pédiatrique : un nombre anormal d'enfants malades à Pont-de-l'Arche, les parents s'inquiètent",
    "TITLE-EN": "Paediatric cancer: an abnormal number of sick children in Pont-de-l'Arche, parents are concerned",
    "DESC-FR": "Sur la commune de Pont-de-l’Arche et Igoville (Eure), 11 cas de cancers pédiatriques ont été enregistrés entre 2017 et 2019. Les parents se mobilisent pour trouver les causes de ces maladies.",
    "DESC-EN": "In the communes of Pont-de-l'Arche and Igoville (Eure), 11 cases of paediatric cancers were recorded between 2017 and 2019. Parents are mobilising to find the causes of these illnesses.",
    "DATE": "19/03/2021",
    "SOURCE": "France 3",
    "LINK": "https://france3-regions.francetvinfo.fr/normandie/eure/cancer-pediatrique-un-nombre-anormal-de-cas-a-pont-de-l-arche-les-parents-s-inquietent-2507129.html"
  },
  {
    "ID": "MEDIA083",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Dans quelle partie du corps a-t-on trouvé mercure, pesticides et plastifiants ?",
    "TITLE-EN": "In what part of their bodies were mercury, pesticides and plasticisers found?",
    "DESC-FR": "Ce sont 26 sénateurs qui se sont prêtés au jeu d’un dépistage de de 1 800 polluants organiques et 49 métaux. Résultats : du mercure, des pesticides et des plastifiants retrouvés dans cette partie du corps…",
    "DESC-EN": "A total of 26 senators were screened for 1,800 organic pollutants and 49 metals. The results: mercury, pesticides and plasticisers were found in this part of the body...",
    "DATE": "28/06/2023",
    "SOURCE": "La Santé au Quotidien",
    "LINK": "https://lasanteauquotidien.com/actualite-sante/dans-quelle-partie-du-corps-a-t-on-trouve-mercure-pesticides-et-plastifiants/"
  },
  {
    "ID": "MEDIA037",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Au pied du Mont-Blanc, les habitants ne supportent plus la pollution de l’air",
    "TITLE-EN": "At the foot of Mont-Blanc, residents can no longer stand the air pollution",
    "DESC-FR": "Dans les Alpes, la vallée de l’Arve accumule les preuves de son haut degré de pollution. Début 2021, de nouvelles analyses alarmantes ont été dévoilées par le collectif de citoyens Coll’Air Pur. Mais les réactions des pouvoirs publics semblent avoir beaucoup de mal à dépasser le stade du discours.",
    "DESC-EN": "In the Alps, the Arve Valley is accumulating evidence of its high pollution levels. At the beginning of 2021, new alarming analyses were unveiled by the citizens' group Coll'Air Pur. But reactions from the public authorities seem to have great difficulty in going beyond the rhetoric stage.",
    "DATE": "26/06/2021",
    "SOURCE": "Reporterre",
    "LINK": "https://reporterre.net/Au-pied-du-Mont-Blanc-les-habitants-ne-supportent-plus-la-pollution-de-l-air"
  },
  {
    "ID": "MEDIA048",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pesticides, produits chimiques, plomb... ce que j'ai trouvé en analysant mes cheveux",
    "TITLE-EN": "Pesticides, chemicals, lead... what I found when I analysed my hair",
    "DESC-FR": "On peut faire attention à son alimentation et découvrir pourtant que son corps est contaminé par divers pesticides et métaux lourds. C’est l’expérience qu'a vécue notre journaliste : elle nous livre ici les résultats de ses analyses et ses résolutions pour éviter ces pollutions.",
    "DESC-EN": "You can be careful about what you eat and yet discover that your body is contaminated by various pesticides and heavy metals. This is the experience of our journalist: here she gives us the results of her analyses and her resolutions to avoid this pollution.",
    "DATE": "04/01/2019",
    "SOURCE": "Reporterre",
    "LINK": "https://reporterre.net/Pesticides-produits-chimiques-plomb-ce-que-j-ai-trouve-en-analysant-mes-cheveux"
  },
  {
    "ID": "MEDIA087",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "LES CHEVEUX DES SÉNATEURS CONTAMINÉS AU MERCURE ET À PLUSIEURS POLLUANTS",
    "TITLE-EN": "SENATORS' HAIR CONTAMINATED WITH MERCURY AND SEVERAL POLLUTANTS",
    "DESC-FR": "INFO RMC. Les cheveux de 26 sénateurs socialistes volontaires ont été analysés par un laboratoire spécialisé pour rechercher une présence de polluants et de métaux. Les résultats sont sans appel: la totalité d'entre eux sont, par exemple, contaminés au mercure.",
    "DESC-EN": "INFO RMC. The hair of 26 volunteer Socialist senators was analysed by a specialised laboratory for the presence of pollutants and metals. The results were indisputable: all of them were contaminated with mercury, for example.",
    "DATE": "26/06/2023",
    "SOURCE": "RMC",
    "LINK": "https://rmc.bfmtv.com/actualites/politique/les-cheveux-des-senateurs-contamines-au-mercure-et-a-plusieurs-polluants_AN-202306260699.html"
  },
  {
    "ID": "MEDIA005",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Glyphosate : pour le maire de Bègles, une analyse de cheveux qui défrise",
    "TITLE-EN": "Glyphosate: for the mayor of Bègles, a hair analysis that makes you nervous",
    "DESC-FR": "Vendredi, Clément Rossignol Puech, le maire écologiste de Bègles\nprésentait à la presse les résultats de l'analyse d'une de ses mèches de\ncheveux",
    "DESC-EN": "On Friday, Clément Rossignol Puech, the ecologist mayor of Bègles\npresented to the press the results of the analysis of one of his locks of hair.",
    "DATE": "08/06/2019",
    "SOURCE": "Rue89Bordeaux",
    "LINK": "https://rue89bordeaux.com/2019/06/glyphosate-maire-de-begles-analyse-de-cheveux-defrise/"
  },
  {
    "ID": "MEDIA028",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Incendie de Lubrizol : Les cheveux des enfants analysés pour connaître l'impact de la pollution",
    "TITLE-EN": "Lubrizol fire: children's hair analysed to determine impact of pollution",
    "DESC-FR": "FUMÉE NOIRE Plus de deux ans après la catastrophe industrielle de Rouen Seine-Maritime), l'association des sinistrés de Lubrizol lance une enquête épidémiologique",
    "DESC-EN": "BLACK SMOKE More than two years after the industrial disaster in Rouen (Seine-Maritime), the Lubrizol victims' association launches an epidemiological investigation",
    "DATE": "02-16-2022",
    "SOURCE": "20minutes",
    "LINK": "https://www.20minutes.fr/planete/3235799-20220216-incendie-lubrizol-cheveux-enfants-analyses-connaitre-impact-pollution"
  },
  {
    "ID": "MEDIA091",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Étude pollution : les cheveux de 26 sénateurs socialistes volontaires sont contaminés au mercure et plusieurs polluants",
    "TITLE-EN": "Pollution study: the hair of 26 volunteer Socialist senators is contaminated with mercury and several pollutants",
    "DESC-FR": "Volontaires pour des analyses toxicologiques, 26 sénateurs socialistes ont été étonnés par les résultats de leurs analyses. Explications.\n\n",
    "DESC-EN": "\"26 Socialist senators volunteered for toxicology tests and were surprised by the results. Explanations.\"",
    "DATE": "27/06/2023",
    "SOURCE": "24Matins",
    "LINK": "https://www.24matins.fr/etude-pollution-les-cheveux-de-26-senateurs-socialistes-volontaires-sont-contamines-au-mercure-et-plusieurs-polluants-1360575"
  },
  {
    "ID": "MEDIA081",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Polluants et métaux détectés dans les cheveux des sénateurs : pourquoi ce n'est \"pas surprenant\", mais toujours inquiétant",
    "TITLE-EN": "Pollutants and metals detected in senators' hair: why it's \"not surprising\", but still worrying",
    "DESC-FR": "Mercure, plastifiants, pesticides, terres rares… Des analyses toxicologiques menées sur vingt-six sénateurs ont révélé la présence souvent massive de polluants et de métaux. Explications.",
    "DESC-EN": "Mercury, plasticisers, pesticides, rare earths... Toxicological analyses carried out on twenty-six senators revealed the presence of often massive quantities of pollutants and metals. Here are some explanations.",
    "DATE": "27/06/2023",
    "SOURCE": "La Montagne",
    "LINK": "https://www.aefinfo.fr/depeche/694694-la-revue-de-presse-du-28-juin-2023-en-developpement-durable"
  },
  {
    "ID": "MEDIA089",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Un cocktail toxique dépisté dans les cheveux des sénateurs",
    "TITLE-EN": "Toxic cocktail detected in senators' hair",
    "DESC-FR": "Le député européen d'EELV Yannick Jadot (2èmeL), la secrétaire nationale d'Europe-Ecologie-Les Verts (EELV) Marine Tondelier et le député d'EELV Julien Bayou assistent à un rassemblement de soutien au mouvement écologiste \"Les Soulèvements de la Terre\" près de l'Hôtel de Ville, à Paris, France, le 21 juin 2023. (AFP).",
    "DESC-EN": "EELV MEP Yannick Jadot (2ndL), Europe-Ecologie-Les Verts (EELV) national secretary Marine Tondelier and EELV MP Julien Bayou attend a rally in support of the environmental movement \"Les Soulèvements de la Terre\" near the Hôtel de Ville, in Paris, France, on 21 June 2023. (AFP).",
    "DATE": "27/06/2023",
    "SOURCE": "ArabNews",
    "LINK": "https://www.arabnews.fr/node/396641/france"
  },
  {
    "ID": "MEDIA064",
    "CAT": "Sénat",
    "FEATURED": true,
    "TYPE": "TV",
    "TITLE-FR": "Les cheveux des sénateurs contaminés aux \"terres rares\"",
    "TITLE-EN": "Senators' hair contaminated with \"rare earths",
    "DESC-FR": "Que peut-on retrouver dans les cheveux des sénateurs français ?",
    "DESC-EN": "What can you find in the hair of French senators?",
    "DATE": "27/06/2023",
    "SOURCE": "BFMTV",
    "LINK": "https://www.bfmtv.com/sante/les-cheveux-des-senateurs-contamines-aux-terres-rares_VN-202306270895.html"
  },
  {
    "ID": "MEDIA073",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Des sénateurs testés positifs aux « terres rares »",
    "TITLE-EN": "Senators tested positive for \"rare earths",
    "DESC-FR": "Les « terres rares » sont des métaux et des composés métalliques utilisés dans la fabrication d'objets de haute technologie qui ont envahi notre quotidien: puces de smartphone, écrans d'ordinateurs portables, batteries...",
    "DESC-EN": "Rare earths are metals and metal compounds used in the manufacture of high-tech objects that have invaded our everyday lives: smartphone chips, laptop screens, batteries, etc.",
    "DATE": "27/06/2023",
    "SOURCE": "Bien Public",
    "LINK": "https://www.bienpublic.com/sante/2023/06/27/des-senateurs-testes-positifs-aux-terres-rares"
  },
  {
    "ID": "MEDIA031",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Métaux lourds à \"toxicité chronique\" sur des cheveux d'enfants en Savoie",
    "TITLE-EN": "Chronically toxic\" heavy metals in children's hair in Savoie",
    "DESC-FR": "Dans la vallée de l'Arve (Haute-Savoie, 74), où règne une importante pollution de l'air, une campagne d'analyses menée sur des cheveux d'enfants à des résultats alarmants, avec une très importante contamination au cadmium. Les résultats confirment le cocktail de polluants toxiques qui impactent nos enfants au quotidien\", dénonce Coll'Air Pur.",
    "DESC-EN": "In the Arve Valley (Haute-Savoie, 74), where there is a high level of air pollution, an analysis campaign carried out on children's hair has produced alarming results, with a very high level of cadmium contamination. The results confirm the cocktail of toxic pollutants that affect our children on a daily basis, says Coll'Air Pur.",
    "DATE": "14/01/2021",
    "SOURCE": "Capital",
    "LINK": "https://www.capital.fr/economie-politique/metaux-lourds-a-toxicite-chronique-sur-des-cheveux-denfants-en-savoie-1390755#:~:text=Dans%20la%20vall%C3%A9e%20de%20l,tr%C3%A8s%20importante%20contamination%20au%20cadmium."
  },
  {
    "ID": "MEDIA080",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pollution : les cheveux des sénateurs intoxiqués aux ‘terres rares’",
    "TITLE-EN": "Pollution: senators' hair poisoned by 'rare earths",
    "DESC-FR": "Les cheveux des sénateurs, testés en laboratoire, ont révélé la présence de produits toxiques, preuve d’une pollution omniprésente.",
    "DESC-EN": "Laboratory tests on the senators' hair revealed the presence of toxic products, proof of pervasive pollution.",
    "DATE": "28/06/2023",
    "SOURCE": "Conso Globe",
    "LINK": "https://www.consoglobe.com/pollution-les-cheveux-des-senateurs-intoxiques-aux-terres-rares-cg"
  },
  {
    "ID": "MEDIA090",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Ne cherchez pas des poux dans la tête de Jérôme Durain, car vous allez trouver des substances toxiques",
    "TITLE-EN": "Don't look for lice in Jérôme Durain's head, because you're going to find toxic substances in his hair",
    "DESC-FR": "Les Sénatrices et Sénateurs socialistes ont fait dépister leurs cheveux. Et les polluants sont en nombre.",
    "DESC-EN": "The Socialist Senators have had their hair tested. And the pollutants are numerous.",
    "DATE": "26/06/2023",
    "SOURCE": "Le Creusot Infos",
    "LINK": "https://www.creusot-infos.com/news/bourgogne-franche-comte/bourgogne-franche-comte/ne-cherchez-pas-des-poux-dans-la-tete-de-jerome-durain-car-vous-allez-trouver-des-substances-toxiques.html"
  },
  {
    "ID": "MEDIA077",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercure, pesticides, \"terres rares\"... L'inquiétante \"contamination\" des cheveux de sénateurs",
    "TITLE-EN": "Mercury, pesticides, \"rare earths\"... The worrying \"contamination\" of senators' hair",
    "DESC-FR": "Des analyses toxicologiques effectuées sur des mèches de cheveux d'élus socialistes volontaires ont montré une prévalence de la détection de \"terres rares\" dans cette population.  ",
    "DESC-EN": "Toxicological analyses carried out on locks of hair from volunteer Socialist councillors showed a prevalence of the detection of \"rare earths\" in this population.",
    "DATE": "27/06/2023",
    "SOURCE": "Egora",
    "LINK": "https://www.egora.fr/actus-pro/sante-publique/81210-mercure-pesticides-terres-rares-l-inquietante-contamination-des"
  },
  {
    "ID": "MEDIA032",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Vallée de l'Arve : des métaux lourds détectés sur des cheveux d'enfants",
    "TITLE-EN": "Vallée de l'Arve: heavy metals detected in children's hair",
    "DESC-FR": "\"Il nous faut connaître les raisons de cette contamination, le tabagisme et les engrais ne pouvant pas expliquer, à eux seuls, une telle intoxication au cadmium en vallée de l'Arve\", observe dans un courrier adressé au préfet le collectif Coll'Air, à l'initiative de la campagne. Celle-ci a révélé la présence de métaux lourds à un niveau de \"toxicité chronique\" dans les cheveux des enfants.",
    "DESC-EN": "\"We need to know the reasons for this contamination, as smoking and fertilisers alone cannot explain such high levels of cadmium poisoning in the Arve Valley\", commented the Coll'Air collective, which initiated the campaign, in a letter to the Prefect. The campaign revealed the presence of \"chronically toxic\" heavy metals in the children's hair.",
    "DATE": "14/01/2021",
    "SOURCE": "Europe 1",
    "LINK": "https://www.europe1.fr/sante/vallee-de-larve-des-metaux-lourds-detectes-sur-des-cheveux-denfants-4018388#:~:text=Une%20campagne%20d'analyses%20men%C3%A9e,niveau%20de%20%22toxicit%C3%A9%20chronique%22."
  },
  {
    "ID": "MEDIA063",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Environnement : les cheveux de sénateurs plus contaminés aux polluants et métaux",
    "TITLE-EN": "Environment: senators' hair more contaminated with pollutants and metals",
    "DESC-FR": "Du mercure, des pesticides, des plastifiants, mais aussi des \"terres rares\", ces métaux utilisés notamment dans les smartphones... Voici ce que révèle l'analyse, dont les résultats ont été publiés mardi, de mèches de cheveux de 26 élus socialistes qui se sont prêtés à l'expérience.",
    "DESC-EN": "Mercury, pesticides, plasticisers, but also \"rare earths\", the metals used in smartphones in particular... These are the findings of an analysis of strands of hair from 26 Socialist MPs who took part in the experiment, the results of which were published on Tuesday.",
    "DATE": "27/06/2023",
    "SOURCE": "France24",
    "LINK": "https://www.france24.com/fr/france/20230627-environnement-une-analyse-d%C3%A9piste-un-cocktail-toxique-dans-les-cheveux-de-s%C3%A9nateurs"
  },
  {
    "ID": "MEDIA056",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques : du plomb et des terres rares retrouvés dans les analyses\ndes enfants de l'Eure",
    "TITLE-EN": "Paediatric cancers: lead and rare earths found in the analyses\nof children in the Eure department.",
    "DESC-FR": "A Igoville et Pont-de-l'Arche dans l'Eure, un nombre important\nde cas de cancers pédiatriques ont été découverts. Pour en\ndéterminer l'origine, des analyses avaient été menées au mois\nde mars sur des enfants. Elles montrent aujourd'hui des traces\nde contamination au plomb et aux terres rares.",
    "DESC-EN": "In Igoville and Pont-de-l'Arche in the Eure department, a significant number cases of paediatric cancer have been discovered. Analyses were carried out in March on children in March. They now show traces of lead and rare earth contamination.",
    "DATE": "10/06/2022",
    "SOURCE": "France Bleu",
    "LINK": "https://www.francebleu.fr/infos/sante-sciences/cancers-pediatriques-du-plomb-et-des-terres-rares-retrouves-chez-des-enfants-dans-l-eure-1654815343"
  },
  {
    "ID": "MEDIA019",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Incendie de Lubrizol : l'association des sinistrés lance une campagne d'analyse sur des cheveux de riverains",
    "TITLE-EN": "Lubrizol fire: the victims' association launches a campaign to analyse the hair of local residents",
    "DESC-FR": "Une nouvelle étape pour l'association des sinistrés de Lubrizol. Elle lance une campagne de dépistage sur des prélèvements de cheveux auprès des habitants de l'agglomération rouennaise. Le but est de déterminer les polluants auxquels les riverains ont été exposés depuis l'incendie de Lubrizol.",
    "DESC-EN": "A new step for the Lubrizol victims' association. It is launching a campaign to take hair samples from residents of the Rouen conurbation. The aim is to determine the pollutants to which local residents have been exposed since the Lubrizol fire.",
    "DATE": "08/02/2022",
    "SOURCE": "France Bleu",
    "LINK": "https://www.francebleu.fr/infos/sante-sciences/incendie-de-lubrizol-l-association-des-sinistres-lance-une-campagne-d-analyse-sur-des-cheveux-de-1644256640"
  },
  {
    "ID": "MEDIA049",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pesticides, fongicides, insecticides... Le maire de Bègles révèle la liste des produits détectés dans son corps",
    "TITLE-EN": "Pesticides, fungicides, insecticides... The mayor of Bègles reveals the list of products detected in his body",
    "DESC-FR": "Le maire de Bègles, Clément Rossignol Puech, a dévoilé vendredi les résultats de l'analyse d'une mèche de ses cheveux. À partir de ce prélèvement, un laboratoire indépendant a pu dresser la liste des produits chimiques qui ont été mangés, bus ou inhalés par l'élu.",
    "DESC-EN": "On Friday, the mayor of Bègles, Clément Rossignol Puech, revealed the results of an analysis of a lock of his hair. From this sample, an independent laboratory was able to draw up a list of the chemicals that had been eaten, drunk or inhaled by the elected official.",
    "DATE": "07/06/2019",
    "SOURCE": "France Bleu",
    "LINK": "https://www.francebleu.fr/infos/sante-sciences/pesticides-fongicides-glyphosate-le-maire-de-begles-revele-la-liste-des-produits-detectes-dans-son-1559929475"
  },
  {
    "ID": "MEDIA072",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pesticides, mercure, terres rares : un cocktail toxique retrouvé dans les cheveux des sénateurs",
    "TITLE-EN": "Pesticides, mercury, rare earths: a toxic cocktail found in senators' hair",
    "DESC-FR": "Des sénateurs, volontaires pour faire tester une mèche de cheveux, ont découvert les résultats des analyses menées par un laboratoire spécialisé. Tous sont contaminés par plusieurs pesticides, mais également par les terres rares, utilisées notamment dans nos smartphones, révèle l'étude ce mardi.",
    "DESC-EN": "Senators who volunteered to have a lock of hair tested discovered the results of analyses carried out by a specialist laboratory. All were contaminated by several pesticides, but also by rare earths, used in particular in our smartphones, the study revealed on Tuesday.",
    "DATE": "27/06/2023",
    "SOURCE": "France Bleu",
    "LINK": "https://www.francebleu.fr/infos/sante-sciences/pesticides-terres-rares-un-cocktail-toxique-retrouve-dans-les-cheveux-des-senateurs-1175236"
  },
  {
    "ID": "MEDIA014",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Sainte-Pazanne : des traces de métaux dans les cheveux des enfants",
    "TITLE-EN": "Sainte-Pazanne: traces of metals in children's hair",
    "DESC-FR": "Les cheveux de 21 enfants du secteur de Sainte-Pazanne ont été analysés après l'annonce des cas groupés de cancers pédiatriques au printemps dernier. Les résultats étonnent jusqu'au laboratoire qui a fait les tests.",
    "DESC-EN": "The hair of 21 children in the Sainte-Pazanne area was analysed following the announcement of the cluster of paediatric cancers last spring. The results surprised even the laboratory that carried out the tests.",
    "DATE": "01/10/2019",
    "SOURCE": "France Bleu",
    "LINK": "https://www.francebleu.fr/infos/sante-sciences/sainte-pazanne-des-traces-de-metaux-dans-les-cheveux-des-enfants-1569912316"
  },
  {
    "ID": "MEDIA027",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Incendie de Lubrizol : deux polluants environnementaux retrouvés après des analyses sur des cheveux d'enfants",
    "TITLE-EN": "Lubrizol fire: two environmental pollutants found after analysis of children's hair",
    "DESC-FR": "L'association des sinistrés de Lubrizol en lien avec un laboratoire avait réalisé une campagne de prélèvement de cheveux fin mars sur 100 enfants à la fois à Serqueux dans le pays de Bray et à Rouen. Deux polluants ont été détectés mais aucun n'a pour origine l'incendie, selon les résultats jeudi.",
    "DESC-EN": "The Lubrizol victims' association, in conjunction with a laboratory, carried out a hair sampling campaign at the end of March on 100 children both in Serqueux in the Bray region and in Rouen. Two pollutants were detected, but neither was the result of the fire, according to the results on Thursday.",
    "DATE": "26/06/2022",
    "SOURCE": "France Bleu",
    "LINK": "https://www.francebleu.fr/infos/societe/seine-maritime-deux-polluants-retrouves-apres-la-campagne-de-prelevement-de-cheveux-sur-des-enfants-1656139783"
  },
  {
    "ID": "MEDIA076",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "« Terres rares », mercure, pesticide… Un cocktail toxique dépisté dans les cheveux des sénateurs",
    "TITLE-EN": "Rare earths, mercury, pesticides... A toxic cocktail detected in senators' hair",
    "DESC-FR": "Que trouve-t-on dans les cheveux des sénateurs et sénatrices ? Une variété aussi intéressante qu’inquiétante.",
    "DESC-EN": "What can be found in the hair of male and female senators? A variety that is as interesting as it is worrying.",
    "DATE": "27/06/2023",
    "SOURCE": "France Live",
    "LINK": "https://www.francelive.fr/teaser/20-minutes/terres-rares-mercure-pesticide-un-cocktail-toxique-depiste-dans-les-cheveux-des-senateurs-8083785/"
  },
  {
    "ID": "MEDIA033",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Vallée de l'Arve: des métaux lourds détectés sur des cheveux d'enfants",
    "TITLE-EN": "Vallée de l'Arve: heavy metals detected in children's hair",
    "DESC-FR": "Une campagne d'analyses menée sur des cheveux d'enfants résidant dans la vallée de l'Arve (Haute-Savoie), où règne une importante pollution de l'air, conclut à la présence de métaux lourds à un niveau de \"toxicité chronique\".",
    "DESC-EN": "A campaign of analyses carried out on the hair of children living in the Arve Valley (Haute-Savoie), where there is a high level of air pollution, concludes that heavy metals are present at a level of \"chronic toxicity\".",
    "DATE": "14/01/2021",
    "SOURCE": "France Soir",
    "LINK": "https://www.francesoir.fr/vallee-de-larve-des-metaux-lourds-detectes-sur-des-cheveux-denfants"
  },
  {
    "ID": "MEDIA053",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques : face à un nombre anormal d'enfants malades, ces parents mènent l'enquête en Normandie",
    "TITLE-EN": "Paediatric cancers: faced with an abnormal number of sick children, these parents are investigating in Normandy",
    "DESC-FR": "En France, quatre clusters de cancers pédiatriques ont\nété signalés ces dernières années, notamment près de\nRouen dans l'Eure et en Seine-Maritime. Et les parents\nsont déterminés à trouver qui est responsable.",
    "DESC-EN": "In France, four clusters of paediatric cancers have been reported in recent years, notably near Rouen in the Rouen in the Eure and Seine-Maritime regions. And parents are determined to find out who is responsible.",
    "DATE": "27/06/2022",
    "SOURCE": "France Info",
    "LINK": "https://www.francetvinfo.fr/replay-radio/le-choix-franceinfo/cancers-pediatriques-face-a-un-nombre-anormal-d-enfants-malades-ces-parents-menent-l-enquete-en-normandie_5194009.html"
  },
  {
    "ID": "MEDIA006",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Désintox : Générations Futures lance une nouvelle campagne sur les polluants chimiques",
    "TITLE-EN": "Désintox: Générations Futures launches a new campaign on chemical pollutants",
    "DESC-FR": "À l'occasion du lancement d'une nouvelle campagne\nsur les polluants chimiques, Générations Futures\npublie les résultats d'une enquête inédite sur la\nprésence de polluants organiques et de métaux\nlourds dans les cheveux de son équipe et de ceux d’enfants.",
    "DESC-EN": "To mark the launch of a new campaign on chemical pollutants, Générations Futures is publishing the results of an unprecedented investigation into the of organic pollutants and heavy metals in the hair of its team and in that of children.",
    "DATE": "29/11/2018",
    "SOURCE": "Générations Futures",
    "LINK": "https://www.generations-futures.fr/actualites/desintox-polluants-chimiques/"
  },
  {
    "ID": "MEDIA088",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Les cheveux des sénateurs socialistes contaminés à plusieurs polluants",
    "TITLE-EN": "The hair of Socialist senators contaminated with several pollutants",
    "DESC-FR": "Des analyses toxicologiques réalisées sur 26 sénateurs volontaires du groupe Socialiste, Écologique et Républicain (SER), dont l'élue PS de l'Oise Laurence Rossignol, révèlent la présence importante de pesticides, de métaux et de « terres rares » dans leurs cheveux.",
    "DESC-EN": "Toxicological analyses carried out on 26 volunteer senators from the Socialiste, Écologique et Républicain (SER) group, including Laurence Rossignol, a Socialist Party elected representative from the Oise region, reveal the presence of pesticides, metals and \"rare earths\" in their hair.",
    "DATE": "27/06/2023",
    "SOURCE": "Courrier Picard",
    "LINK": "https://www.courrier-picard.fr/id427423/article/2023-06-27/les-cheveux-des-senateurs-socialistes-contamines-plusieurs-polluants"
  },
  {
    "ID": "MEDIA084",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pesticides, mercure, terres rares... On trouve de tout dans les cheveux d’un sénateur nordiste",
    "TITLE-EN": "Pesticides, mercury, rare earths... Everything can be found in the hair of a senator from Northern France",
    "DESC-FR": "Patrick Kanner, sénateur (PS) du Nord et président du groupe socialiste au Sénat, a comme 25 de ses collègues décidé de faire analyser ses cheveux, dans lesquels un laboratoire a retrouvé de nombreux polluants.",
    "DESC-EN": "Patrick Kanner, Senator (PS) for Nord and Chairman of the Socialist Group in the Senate, has decided, like 25 of his colleagues, to have his hair analysed, after a laboratory found numerous pollutants in it.",
    "DATE": "28/06/2023",
    "SOURCE": "La Voix du Nord",
    "LINK": "https://www.lavoixdunord.fr/1346183/article/2023-06-28/pesticides-mercure-terres-rares-trouve-de-tout-dans-les-cheveux-d-un-senateur"
  },
  {
    "ID": "MEDIA082",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Que trouve-t-on dans les cheveux des sénateurs ?",
    "TITLE-EN": "What is found in senators' hair?",
    "DESC-FR": "Paris, le mercredi 28 juin 2023 — Vingt-six sénateurs socialistes ont accepté de faire analyser une mèche de leurs cheveux, afin de rechercher d'éventuels polluants. Les résultats, publiés hier, semblent assez alarmants quoique difficiles à interpréter",
    "DESC-EN": "Paris, Wednesday 28 June 2023 - Twenty-six Socialist senators have agreed to have a lock of their hair analysed for possible pollutants. The results, published yesterday, seem rather alarming, although difficult to interpret.",
    "DATE": "28/06/2023",
    "SOURCE": "JIM",
    "LINK": "https://www.jim.fr/medecin/actualites/pro_societe/e-docs/que_trouve_t_on_dans_les_cheveux_des_senateurs__197912/document_actu_pro.phtml"
  },
  {
    "ID": "MEDIA043",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "REPORTAGE. L'arsenic de Salsigne : les jardins de Conques-sur-Orbiel entre science et défiance",
    "TITLE-EN": "REPORTAGE. The arsenic of Salsigne: the gardens of Conques-sur-Orbiel between science and mistrust",
    "DESC-FR": "À Conques-sur-Orbiel, sur les terrains submergés par la crue il y a trois ans, il y a ceux qui ont renoncé à cultiver leur jardin et ceux qui continuent à produire pour leur seule consommation. Les arrêtés interdisant la mise sur le marché des légumes se succèdent régulièrement depuis 1997, à cause de l’arsenic...",
    "DESC-EN": "In Conques-sur-Orbiel, on the land submerged by the flood three years ago, there are those who have given up cultivating their gardens and those who continue to produce for their own consumption. Orders banning the sale of vegetables have been issued regularly since 1997, because of the arsenic...",
    "DATE": "25/10/2021",
    "SOURCE": "La Dépêche",
    "LINK": "https://www.ladepeche.fr/2021/10/24/reportage-larsenic-de-salsigne-les-jardins-de-conques-sur-orbiel-entre-science-et-defiance-9888527.php"
  },
  {
    "ID": "MEDIA054",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Plusieurs cas de mystérieux cancers pédiatriques touchent deux villages de l'Eure",
    "TITLE-EN": "Several cases of mysterious paediatric cancers affect two villages in the Eure department",
    "DESC-FR": "Dix-sept enfants ont été atteints de cancer depuis 2017 dans deux petites\ncommunes voisines du département de l'Eure, Igoville et Pont de l'Arche. Les\nparents inquiets ont fait analyser les cheveux de onze d'entre eux dans le but\nd'identifier une cause à l'épidémie.",
    "DESC-EN": "Seventeen children have been diagnosed with cancer since 2017 in two small neighboring towns in the Eure department, Igoville and Pont de l'Arche. Concerned parents have had the hair of eleven of them analyzed in a bid to identify a cause for the epidemic.",
    "DATE": "14/06/2022",
    "SOURCE": "La Dépêche",
    "LINK": "https://www.ladepeche.fr/2022/06/14/plusieurs-cas-de-mysterieux-cancers-pediatriques-touchent-deux-villages-de-leure-10364638.php#:~:text=Plusieurs%20cas%20de%20myst%C3%A9rieux%20cancers%20p%C3%A9diatriques%20touchent%20deux%20villages%20de%20l%27Eure,-Les%20cheveux%20de&text=Dix%2Dsept%20enfants%20ont%20%C3%A9t%C3%A9,et%20Pont%20de%20l%27Arche."
  },
  {
    "ID": "MEDIA079",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "\"On est contaminés\" : mercure, pesticides, plastifiants... Ce mélange toxique inquiétant retrouvé dans les cheveux des sénateurs français",
    "TITLE-EN": "We're contaminated: mercury, pesticides, plasticisers... This worrying toxic mix found in the hair of French senators",
    "DESC-FR": "Que trouve-t-on dans les cheveux des sénateurs et sénatrices ? Du mercure, des pesticides, des plastifiants, mais aussi des \"terres rares\", ces métaux utilisés dans les smartphones et autres objets de haute technologie.",
    "DESC-EN": "What can be found in the hair of male and female senators? Mercury, pesticides, plasticisers, but also \"rare earths\", the metals used in smartphones and other high-tech objects.",
    "DATE": "27/06/2023",
    "SOURCE": "La Dépêche",
    "LINK": "https://www.ladepeche.fr/2023/06/27/on-est-contamines-mercure-pesticides-plastifiants-ce-melange-toxique-inquietant-retrouve-dans-les-cheveux-des-senateurs-francais-11304030.php"
  },
  {
    "ID": "MEDIA094",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "ENVIRONNEMENT : LES CHEVEUX DES SÉNATEURS PLUS CONTAMINÉS AUX POLLUANTS ET AUX MÉTAUX",
    "TITLE-EN": "ENVIRONMENT: SENATORS' HAIR MORE CONTAMINATED WITH POLLUTANTS AND METALS",
    "DESC-FR": "Des polluants, des métaux, des \"terres rares\"... ",
    "DESC-EN": "Pollutants, metals, rare earths...",
    "DATE": "27/06/2023",
    "SOURCE": "Gazette",
    "LINK": "https://www.lagazetteaz.fr/news/sentence/12841.html"
  },
  {
    "ID": "MEDIA021",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pollution à la fonderie de Bléré : la crainte d'un scandale sanitaire",
    "TITLE-EN": "Pollution at the Bléré foundry: fears of a health scandal",
    "DESC-FR": "Malgré les appels du maire à ne pas s'alarmer en l'attente de nouveaux prélèvements, des habitants de Bléré s’inquiètent de l’ampleur de la pollution détectée près de l'ancienne fonderie, à l'arrêt depuis 2009.",
    "DESC-EN": "Despite appeals from the mayor not to be alarmed pending further sampling, residents of Bléré are concerned about the extent of the pollution detected near the former foundry, which has been shut down since 2009.",
    "DATE": "02/05/2022",
    "SOURCE": "La Nouvelle République",
    "LINK": "https://www.lanouvellerepublique.fr/indre-et-loire/commune/athee-sur-cher/pollution-a-la-fonderie-de-blere-la-crainte-d-un-scandale-sanitaire"
  },
  {
    "ID": "MEDIA092",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Un cocktail toxique dépisté dans les cheveux des sénateurs",
    "TITLE-EN": "Toxic cocktail detected in senators' hair",
    "DESC-FR": "Que trouve-t-on dans les cheveux des sénateurs et sénatrices ? Du mercure, des pesticides, des plastifiants, mais aussi des \"terres rares\", ces métaux utilisés dans les smartphones et autres objets de haute technologie, révèle une analyse conduite chez 26 élus socialistes.",
    "DESC-EN": "What can be found in the hair of male and female senators? Mercury, pesticides, plasticisers, but also \"rare earths\", the metals used in smartphones and other high-tech objects, reveals an analysis carried out on 26 Socialist elected representatives.",
    "DATE": "27/06/2023",
    "SOURCE": "La Provence",
    "LINK": "https://www.laprovence.com/article/france-monde/9568498905371215/un-cocktail-toxique-depiste-dans-les-cheveux-des-senateurs"
  },
  {
    "ID": "MEDIA039",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Vallée de l'Orbiel : les habitants exposés à une multitude de métaux lourds",
    "TITLE-EN": "Vallée de l'Orbiel: résidents exposed to a multitude of heavy metals",
    "DESC-FR": "Mercure, plomb, argent... Les habitants de l'ancien bassin minier de Salsigne dans l'Aude, sont exposés à une multitude de métaux lourds «toxiques», au-delà de l'arsenic, ont alerté vendredi 16 octobre des associations ayant mené leur propre campagne d'analyses.",
    "DESC-EN": "Mercury, lead, silver... Residents of the former Salsigne coalfield in the Aude department are exposed to a multitude of \"toxic\" heavy metals, in addition to arsenic, warned associations on Friday 16 October after they carried out their own analysis campaign.",
    "DATE": "16/10/2020",
    "SOURCE": "Le Figaro",
    "LINK": "https://www.lefigaro.fr/flash-actu/vallee-de-l-orbiel-les-habitants-exposes-a-une-multitude-de-metaux-lourds-20201016"
  },
  {
    "ID": "MEDIA066",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Les cheveux des sénateurs socialistes contaminés aux «terres rares» et à plusieurs polluants",
    "TITLE-EN": "The hair of Socialist senators contaminated with \"rare earths\" and several pollutants",
    "DESC-FR": "Un tiers du groupe socialiste au Sénat s'est soumis à une analyse toxicologique de ses cheveux. Tous sont contaminés au mercure, certains à des niveaux préoccupants.",
    "DESC-EN": "One third of the Socialist Group in the Senate had their hair toxicologically analysed. All were contaminated with mercury, some to worrying levels.",
    "DATE": "26/06/2023",
    "SOURCE": "Le Figaro",
    "LINK": "https://www.lefigaro.fr/politique/les-cheveux-des-senateurs-socialistes-contamines-aux-terres-rares-et-a-plusieurs-polluants-20230627"
  },
  {
    "ID": "MEDIA035",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Au pied du Mont-Blanc, les habitants de la vallée de l'Arve aux prises avec une pollution persistante",
    "TITLE-EN": "At the foot of Mont-Blanc, the inhabitants of the Arve Valley grapple with persistent pollution",
    "DESC-FR": "Une étude a été réalisée sur 76 enfants et 6 adultes à l'initiative du collectif Coll'Air Pur et révèle la présence de métaux lourds dans leurs cheveux. 60% d'entre eux présentent des conséquences dermatologiques et un tiers des troubles du sommeil.",
    "DESC-EN": "A study was carried out on 76 children and 6 adults on the initiative of the Coll'Air Pur collective, revealing the presence of heavy metals in their hair. 60% of them had dermatological consequences, and a third suffered sleep disorders.",
    "DATE": "25/01/2021",
    "SOURCE": "Le Figaro",
    "LINK": "https://www.lefigaro.fr/sciences/au-pied-du-mont-blanc-les-habitants-de-la-vallee-de-l-arve-aux-prises-avec-une-pollution-persistante-20210125#:~:text=Sciences%20%26%20Environnement-,Au%20pied%20du%20Mont%2DBlanc%2C%20les%20habitants%20de%20la%20vall%C3%A9e,prises%20avec%20une%20pollution%20persistante&text=Une%20%C3%A9tude%20a%20%C3%A9t%C3%A9%20r%C3%A9alis%C3%A9e,m%C3%A9taux%20lourds%20dans%20leurs%20cheveux."
  },
  {
    "ID": "MEDIA040",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercure, arsenic, plomb... les habitants de la vallée de l'Orbiel exposés à un cocktail de produits toxiques",
    "TITLE-EN": "Mercury, arsenic, lead... residents of the Orbiel valley exposed to a cocktail of toxic products",
    "DESC-FR": "Deux ans après les inondations dans l'Aude, des analyses toxicologiques révèlent la présence de plusieurs métaux lourds chez les riverains de l'ancienne mine d'or de Salsigne.",
    "DESC-EN": "Two years after the floods in the Aude department, toxicological analyses have revealed the presence of several heavy metals in people living near the former Salsigne gold mine.",
    "DATE": "16/10/2020",
    "SOURCE": "Le Monde",
    "LINK": "https://www.lemonde.fr/planete/article/2020/10/16/mercure-arsenic-plomb-les-habitants-de-la-vallee-de-l-orbiel-exposes-a-un-cocktail-de-produits-toxiques_6056246_3244.html#:~:text=Pollutions-,Mercure%2C%20arsenic%2C%20plomb%E2%80%A6%20les%20habitants%20de%20la%20vall%C3%A9e%20de,mine%20d'or%20de%20Salsigne."
  },
  {
    "ID": "MEDIA074",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercure, pesticides, plastifiants : un cocktail toxique dépisté dans les cheveux des sénateurs",
    "TITLE-EN": "Mercury, pesticides, plasticisers: a toxic cocktail detected in senators' hair",
    "DESC-FR": "La présence des terres rares, plus importante que celle rencontrée chez la population générale, peut être expliquée, selon le laboratoire, par l'utilisation importante et régulière des téléphones, ordinateurs portables et tablettes.",
    "DESC-EN": "The presence of rare earths, which is higher than that found in the general population, can be explained, according to the laboratory, by the extensive and regular use of mobile phones, laptops and tablets.",
    "DATE": "27/06/2023",
    "SOURCE": "Le Monde",
    "LINK": "https://www.lemonde.fr/sciences/article/2023/06/27/mercure-pesticides-plastifiants-un-cocktail-toxique-depiste-dans-les-cheveux-des-senateurs_6179366_1650684.html"
  },
  {
    "ID": "MEDIA030",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pollution : trop de cadmium chez les enfants de la vallée de l'Arve",
    "TITLE-EN": "Pollution: too much cadmium in Arve Valley children",
    "DESC-FR": "Une analyse indépendante de cheveux montre un taux du métal lourd trois fois supérieur à la norme.",
    "DESC-EN": "An independent analysis of hair showed levels of the heavy metal three times higher than the norm.",
    "DATE": "15/12/2020",
    "SOURCE": "Le Parisien",
    "LINK": "https://www.leparisien.fr/environnement/pollution-trop-de-cadmium-chez-les-enfants-de-la-vallee-de-l-arve-15-12-2020-8414303.php"
  },
  {
    "ID": "MEDIA065",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercure, terres rares et pesticides retrouvés dans les analyses de cheveux de 26 sénateurs PS",
    "TITLE-EN": "Mercury, rare earths and pesticides found in the hair of 26 Socialist Party senators",
    "DESC-FR": "INFO LE PARISIEN. Un tiers du groupe socialiste au Sénat s'est soumis volontairement à une analyse toxicologique de leurs cheveux réalisée par le laboratoire ToxSeek. Tous sont contaminés à des degrés divers, certains à des niveaux préoccupants.",
    "DESC-EN": "INFO LE PARISIEN. A third of the Socialist group in the Senate voluntarily submitted to a toxicological analysis of their hair by the laboratory ToxSeek. All were contaminated to varying degrees, some to worrying levels.",
    "DATE": "26/06/2023",
    "SOURCE": "Le Parisien",
    "LINK": "https://www.leparisien.fr/politique/mercure-terres-rares-et-pesticides-retrouves-dans-les-analyses-de-cheveux-de-26-senateurs-ps-26-06-2023-CKZS35SABVGELO6WUBDM5FYK4E.php"
  },
  {
    "ID": "MEDIA012",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers à Sainte-Pazanne : des polluants dans les cheveux d'enfants, selon les parents",
    "TITLE-EN": "Cancers in Sainte-Pazanne: pollutants in children's hair, according to parents",
    "DESC-FR": "Un collectif de parents, qui demande plus de\ntransparence sur les 17 cas de cancers recensés dans les\nenvirons de Sainte-Pazanne, a réalisé de nouvelles\nanalyses toxicologiques.",
    "DESC-EN": "A group of parents calling for greater transparency about the 17 cases of cancer reported in the Sainte-Pazanne, have carried out new toxicological analyses.",
    "DATE": "30/09/2019",
    "SOURCE": "Le Parisien",
    "LINK": "https://www.leparisien.fr/societe/cancers-a-sainte-pazanne-des-polluants-dans-les-cheveux-d-enfants-selon-les-parents-30-09-2019-8162952.php#:~:text=Ce%20lundi%2C%20il%20a%20alert%C3%A9,l'un%20est%20r%C3%A9cemment%20d%C3%A9c%C3%A9d%C3%A9."
  },
  {
    "ID": "MEDIA067",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercure, pesticides et terres rares retrouvés dans les cheveux des sénateurs",
    "TITLE-EN": "Mercury, pesticides and rare earths found in senators' hair",
    "DESC-FR": "Des analyses menées sur 26 élus volontaires ont mis en évidence un taux de 93 % de sénateurs contaminés aux « terres rares », un niveau supérieur à la population.",
    "DESC-EN": "Analyses carried out on 26 elected volunteers revealed that 93% of senators were contaminated with \"rare earths\", a level higher than the general population.",
    "DATE": "27/06/2023",
    "SOURCE": "Le Point",
    "LINK": "https://www.lepoint.fr/politique/mercure-pesticides-et-terres-rares-retrouves-dans-les-cheveux-des-senateurs-27-06-2023-2526307_20.php"
  },
  {
    "ID": "MEDIA036",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Vallée de l'Arve : une forte contamination au cadmium retrouvée dans des cheveux d'enfants",
    "TITLE-EN": "Vallée de l'Arve: heavy cadmium contamination found in children's hair",
    "DESC-FR": "Le collectif Coll'Air Pur a organisé le 16 septembre une opération de recherche de métaux sur les cheveux de 76 enfants et 6 adultes résidant dans la vallée de l'Arve. Les résultats sont alarmants.",
    "DESC-EN": "On 16 September, the Coll'Air Pur collective organised an operation to search for metals in the hair of 76 children and 6 adults living in the Arve valley. The results were alarming.",
    "DATE": "28/01/2021",
    "SOURCE": "Les Echos",
    "LINK": "https://www.lesechos.fr/pme-regions/auvergne-rhone-alpes/vallee-de-larve-une-forte-contamination-au-cadmium-retrouvee-dans-des-cheveux-denfants-1285178"
  },
  {
    "ID": "MEDIA061",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Des terres rares et substances toxiques trouvées dans les cheveux de sénateurs",
    "TITLE-EN": "Rare earths and toxic substances found in senators' hair",
    "DESC-FR": "Des analyses toxicologiques menées sur 26 sénateurs et sénatrices socialistes volontaires montrent la présence de mercure, pesticides, plastifiants, mais aussi de terres rares, révèle le groupe ce mardi.",
    "DESC-EN": "Toxicological tests carried out on 26 volunteer Socialist senators have revealed the presence of mercury, pesticides, plasticisers and rare earth elements, the group revealed on Tuesday.",
    "DATE": "27/06/2023",
    "SOURCE": "Les Echos",
    "LINK": "https://www.lesechos.fr/politique-societe/societe/des-terres-rares-et-substances-toxiques-trouvees-dans-des-cheveux-de-senateurs-1956208"
  },
  {
    "ID": "MEDIA085",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Que trouve-t-on dans les cheveux des sénateurs ? Voici ce que révèle une analyse",
    "TITLE-EN": "What is found in senators' hair? Here's what an analysis reveals",
    "DESC-FR": "Une analyse conduite chez 26 élus socialistes a été menée par le laboratoire privé et indépendant toxSeek, qui a réalisé un dépistage de 1 800 polluants organiques et 49 métaux.",
    "DESC-EN": "An analysis of 26 Socialist councillors was carried out by the private, independent laboratory toxSeek, which screened for 1,800 organic pollutants and 49 metals.",
    "DATE": "27/06/2023",
    "SOURCE": "Telegramme",
    "LINK": "https://www.letelegramme.fr/france/que-trouve-t-on-dans-les-cheveux-des-senateurs-voici-ce-que-revele-une-etude-6381159.php"
  },
  {
    "ID": "MEDIA034",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Enfants de Passy, des métaux lourds plein la tête",
    "TITLE-EN": "Children in Passy, heavy metals in their heads",
    "DESC-FR": "Dans cette commune de la vallée de l'Arve, du cadmium, particule cancérigène, a été retrouvé dans des cheveux en grande quantité. Les sources de pollution sont multiples mais l'incinérateur est dans le viseur",
    "DESC-EN": "In this commune in the Arve valley, cadmium, a carcinogenic particle, was found in large quantities in hair. There are many sources of pollution, but the incinerator is in the firing line",
    "DATE": "18/01/2021",
    "SOURCE": "Le Temps",
    "LINK": "https://www.letemps.ch/suisse/enfants-passy-metaux-lourds-plein-tete"
  },
  {
    "ID": "MEDIA062",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Parlement'hair\nMercure, pesticides, terres rares… dans les cheveux des sénateurs, les polluants foisonnent",
    "TITLE-EN": "Parliament'hair\nMercury, pesticides, rare earths... pollutants abound in senators' hair.",
    "DESC-FR": "En juillet 2022, 26 élus socialistes avaient confié une mèche de leurs cheveux au laboratoire toxSeek qui a réalisé un dépistage de 1 800 polluants organiques et 49 métaux. Les résultats publiés ce mardi 27 juin sont inquiétants.",
    "DESC-EN": "In July 2022, 26 Socialist elected representatives entrusted a lock of their hair to the toxSeek laboratory, which screened them for 1,800 organic pollutants and 49 metals. The results published on Tuesday 27 June are worrying.",
    "DATE": "27/06/2023",
    "SOURCE": "Libération",
    "LINK": "https://www.liberation.fr/environnement/pollution/mercure-pesticides-terres-rares-dans-les-cheveux-des-senateurs-les-polluants-foisonnent-20230627_F5J33KNNEVGEDFREYRL5I4YXFE/"
  },
  {
    "ID": "MEDIA086",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Les cheveux des sénateurs socialistes contiennent des polluants et des métaux",
    "TITLE-EN": "The hair of Socialist senators contains pollutants and metals",
    "DESC-FR": "Plus d'une vingtaine de sénateurs, tous issus du parti socialiste, ont volontairement accepté d'analyser leurs cheveux. Le test a été réalisé par un laboratoire privé et les résultats sont tombés ce mardi 27 juin.",
    "DESC-EN": "More than twenty senators, all from the Socialist Party, voluntarily agreed to have their hair analysed. The test was carried out by a private laboratory and the results were released on Tuesday 27 June.",
    "DATE": "27/06/2023",
    "SOURCE": "L'Info.re",
    "LINK": "https://www.linfo.re/france/politique/les-cheveux-des-senateurs-socialistes-contiennent-des-polluants-et-des-metaux"
  },
  {
    "ID": "MEDIA038",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Vallée de l'Orbiel: les habitants exposés à une multitude de métaux lourds (associations)",
    "TITLE-EN": "Vallée de l'Orbiel: résidents exposed to a multitude of heavy metals (associations)",
    "DESC-FR": "Mercure, plomb, argent... Les habitants de l'ancien bassin minier de Salsigne dans l'Aude, sont exposés à une multitude de métaux lourds \"toxiques\", au-delà de l'arsenic, ont alerté vendredi des associations ayant mené leur propre campagne d'analyses.",
    "DESC-EN": "Mercury, lead, silver... Residents of the former Salsigne coalfield in the Aude department are exposed to a multitude of \"toxic\" heavy metals, in addition to arsenic, associations warned on Friday after conducting their own analysis camp",
    "DATE": "16/10/2020",
    "SOURCE": "L'info durable",
    "LINK": "https://www.linfodurable.fr/vallee-de-lorbiel-les-habitants-exposes-une-multitude-de-metaux-lourds-associations-20881"
  },
  {
    "ID": "MEDIA009",
    "CAT": "Environnement",
    "FEATURED": true,
    "TYPE": "Presse",
    "TITLE-FR": "Glyphosate, perturbateurs endocriniens : testés, nos enfants sont contaminés",
    "TITLE-EN": "Glyphosate, endocrine disrupters: when tested, our children are contaminated",
    "DESC-FR": "Soumettre nos enfants, à la rédaction de Marie Claire, à un test,\nle toxSeek, conçu pour détecter dans l'organisme les molécules\ntoxiques : c'est l'idée que nous avons eue pour tenter d'évaluer\nl'impact du grand bain chimique dans lequel ils évoluent\nquotidiennement. ",
    "DESC-EN": "Subjecting our children, Marie Claire's editorial team, to a test,\nthe toxSeek, designed to detect toxic molecules in the body.\nmolecules in the body: that's the idea we came up with to try to assess\nthe impact of the huge chemical bath in which they are immersed\nin on a daily basis. ",
    "DATE": "21/09/2018",
    "SOURCE": "Marie Claire",
    "LINK": "https://press.toxseek.com/assets/test-toxseek-marie-claire%20%282023_06_11%2018_54_22%20UTC%29.pdf"
  },
  {
    "ID": "MEDIA068",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Plastique, pesticide, « terres rares »… Un cocktail toxique dépisté dans les cheveux des sénateurs",
    "TITLE-EN": "Plastics, pesticides, \"rare earths\"... A toxic cocktail detected in senators' hair",
    "DESC-FR": "Une vingtaine d'élus socialistes ont fait pratiquer une analyse sur des mèches de leurs cheveux : le résultat, sans appel, peut être transposé à une bonne partie de la population.",
    "DESC-EN": "Une vingtaine d'élus socialistes ont fait pratiquer une analyse sur des mèches de leurs cheveux : le résultat, sans appel, peut être transposé à une bonne partie de la population.",
    "DATE": "27/06/2023",
    "SOURCE": "L'Obs",
    "LINK": "https://www.nouvelobs.com/ecologie/20230627.OBS75002/plastique-pesticide-terres-rares-un-cocktail-toxique-depiste-dans-les-cheveux-des-senateurs.html"
  },
  {
    "ID": "MEDIA007",
    "CAT": "Environnement",
    "FEATURED": true,
    "TYPE": "Presse",
    "TITLE-FR": "Dans nos assiettes, il y a tout... même des pesticides interdits",
    "TITLE-EN": "Everything's on our plates... even banned pesticides",
    "DESC-FR": "L'ONG Générations Futures a détecté la présence de\nmolécules dangereuses dans nos organismes. En cause :\nles aliments importés hors d'UE.",
    "DESC-EN": "The NGO Générations Futures has detected the presence of\ndangerous molecules in our bodies. The cause:\nfood imported from outside the EU.",
    "DATE": "28/11/2018",
    "SOURCE": "L'Obs",
    "LINK": "https://www.nouvelobs.com/planete/20181128.OBS6203/dans-nos-assiettes-il-y-a-tout-meme-des-pesticides-interdits.html"
  },
  {
    "ID": "MEDIA069",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Qu'y a-t-il dans les cheveux des sénateurs ? Les inquiétants résultats d'une analyse dévoilés",
    "TITLE-EN": "What's in senators' hair? The worrying results of an analysis revealed",
    "DESC-FR": "Du mercure, quelques dizaines de pesticides différents, dont un interdit en Europe, et même des « terres rares », ont été trouvés dans les cheveux de 26 sénateurs socialistes qui se soumis à une analyse, dont les résultats ont été dévoilés mardi 27 juin 2023.",
    "DESC-EN": "Mercury, several dozen different pesticides, including one banned in Europe, and even \"rare earths\" were found in the hair of 26 Socialist senators who underwent an analysis, the results of which were revealed on Tuesday 27 June 2023.",
    "DATE": "27/06/2023",
    "SOURCE": "Ouest France",
    "LINK": "https://www.ouest-france.fr/environnement/pollution/quy-a-t-il-dans-les-cheveux-des-senateurs-les-inquietants-resultats-dune-analyse-devoiles-a88d6138-14c7-11ee-ae57-25b1d1d54a03"
  },
  {
    "ID": "MEDIA013",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques en Loire-Atlantique. Des laboratoires de plus en plus sollicités",
    "TITLE-EN": "Paediatric cancers in Loire-Atlantique. Laboratories increasingly in demand",
    "DESC-FR": "Les analyses coûtent cher. Stop aux cancers de nos enfants en finance quelques-unes. Des laboratoires s'emparent du sujet pour tenter de comprendre.",
    "DESC-EN": "Analyses are expensive. Stop aux cancers de nos enfants is funding some of them. Laboratories are getting to grips with the subject to try and understand.",
    "DATE": "01/09/2019",
    "SOURCE": "Ouest France",
    "LINK": "https://www.ouest-france.fr/pays-de-la-loire/sainte-pazanne-44680/cancers-pediatriques-en-loire-atlantique-des-laboratoires-de-plus-en-plus-sollicites-66cda9e0-fa50-11ea-b1d5-5ceb51cac421"
  },
  {
    "ID": "MEDIA017",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Après l’incendie de Lubrizol, une association de sinistrés lance une analyse de cheveux",
    "TITLE-EN": "After the Lubrizol fire, a disaster victims' association launches a hair analysis campaign",
    "DESC-FR": "Le 26 mars 2022, l’Association des sinistrés de Lubrizol fera réaliser des analyses de cheveux provenant d'une centaine d’enfants. Avec l'idée de dépister les polluants hérités de l'incendie du 26 septembre 2019 à Rouen.",
    "DESC-EN": "On 26 March 2022, the Association des sinistrés de Lubrizol (Lubrizol disaster victims' association) will be carrying out hair analyses on around a hundred children. The aim is to detect pollutants left over from the fire in Rouen on 26 September 2019.",
    "DATE": "21/02/2022",
    "SOURCE": "Ouest France",
    "LINK": "https://www.ouest-france.fr/societe/lubrizol/apres-l-incendie-de-lubrizol-une-association-de-sinistres-lance-une-analyse-de-cheveux-44771b66-931e-11ec-bf8f-fa3957a31fb0"
  },
  {
    "ID": "MEDIA016",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Incendie de Lubrizol : un dépistage des polluants sur les cheveux prévu à Serqueux et Rouen",
    "TITLE-EN": "Lubrizol fire: hair screening for pollutants planned in Serqueux and Rouen",
    "DESC-FR": "Une session de prélèvements sera organisée samedi 26 mars 2022, en parallèle de celle qui se déroulera à Rouen, par l’Association des sinistrés de Lubrizol.",
    "DESC-EN": "A sampling session will be organised on Saturday 26 March 2022, alongside the one taking place in Rouen, by the Association des sinistrés de Lubrizol.",
    "DATE": "22/03/2022",
    "SOURCE": "Paris-Normandie",
    "LINK": "https://www.paris-normandie.fr/id290059/article/2022-03-22/lubrizol-un-depistage-des-polluants-sur-les-cheveux-prevu-serqueux-dans-le-pays"
  },
  {
    "ID": "MEDIA051",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Cancers pédiatriques dans l’Eure : du plomb et des terres rares retrouvés dans les cheveux",
    "TITLE-EN": "Paediatric cancers in Eure: lead and rare earths found in hair",
    "DESC-FR": "Pour tenter de trouver les causes d'un nombre élevé de cancers pédiatriques à Pont-de-l'Arche et Igoville, des parents avaient réalisé des prélèvements de cheveux sur des enfants. Les résultats ont été rendus jeudi 9 juin 2022.",
    "DESC-EN": "In an attempt to find the causes of a high number of paediatric cancers in Pont-de-l'Arche and Igoville, parents took hair samples from children. The results were released on Thursday 9 June 2022.",
    "DATE": "09/06/2022",
    "SOURCE": "Paris-Normandie",
    "LINK": "https://www.paris-normandie.fr/id314543/article/2022-06-09/cancers-pediatriques-dans-leure-du-plomb-et-des-terres-rares-retrouves-dans-les#:~:text=D%C3%A9connexion%20Accueil%20Actualit%C3%A9s-,Cancers%20p%C3%A9diatriques%20dans%20l'Eure%20%3A%20du%20plomb%20et%20des%20terres,rendus%20jeudi%209%20juin%202022."
  },
  {
    "ID": "MEDIA026",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Rouen. Que donnent les analyses de cheveux d'enfants menées par l’association des sinistrés de Lubrizol ?",
    "TITLE-EN": "Rouen. What do the analyses of children's hair carried out by the Lubrizol victims' association reveal?",
    "DESC-FR": "L’association des sinistrés de Lubrizol a mené une campagne d'analyse de cheveux sur une centaine d’enfants. Un pesticide interdit depuis 20 ans et des phosphates sont mis en évidence.",
    "DESC-EN": "The Lubrizol disaster victims' association carried out a hair analysis campaign on around a hundred children. A pesticide banned 20 years ago and phosphates were found.",
    "DATE": "23/06/2022",
    "SOURCE": "Paris-Normandie",
    "LINK": "https://www.paris-normandie.fr/id319107/article/2022-06-23/que-donnent-les-analyses-de-cheveux-rouen-menee-par-lassociation-des-sinistres"
  },
  {
    "ID": "MEDIA071",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Pesticides, mercure, terres rares : les cheveux des sénateurs contaminés",
    "TITLE-EN": "Pesticides, mercury, rare earths: senators' hair contaminated",
    "DESC-FR": "26 sénateurs socialistes ont accepté de soumettre une mèche de leurs cheveux à une analyse toxicologique et les résultats sont inquiétants. Ils font état de la présence de mercure, pesticides, plastifiants mais aussi des terres rares. A l'origine de cette initiative, la sénatrice, Angèle Préville estime avoir « des preuves tangibles » de la contamination des Français à différents polluants.",
    "DESC-EN": "26 Socialist senators agreed to submit a lock of their hair to a toxicological analysis, and the results are worrying. They reveal the presence of mercury, pesticides, plasticisers and rare earth elements. The senator behind the initiative, Angèle Préville, believes she has \"tangible proof\" of the contamination of French people by various pollutants.",
    "DATE": "27/06/2023",
    "SOURCE": "Public Sénat",
    "LINK": "https://www.publicsenat.fr/actualites/societe/pesticides-mercure-terres-rares-les-cheveux-des-senateurs-contamines"
  },
  {
    "ID": "MEDIA075",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Un cocktail toxique dépisté dans les cheveux des sénateurs",
    "TITLE-EN": "Toxic cocktail found in senators' hair",
    "DESC-FR": "Que trouve-t-on dans les cheveux des sénateurs et sénatrices ? Du mercure, des pesticides, des plastifiants, mais aussi des \"terres rares\", ces métaux utilisés dans les smartphones et autres objets de haute technologie, révèle une analyse conduite chez 26 élus socialistes.",
    "DESC-EN": "What can be found in the hair of male and female senators? Mercury, pesticides, plasticisers, but also \"rare earths\", the metals used in smartphones and other high-tech objects, reveals an analysis carried out on 26 Socialist elected representatives.",
    "DATE": "26/06/2023",
    "SOURCE": "Sciences et Avenir",
    "LINK": "https://www.sciencesetavenir.fr/sante/un-cocktail-toxique-depiste-dans-les-cheveux-des-senateurs_172179"
  },
  {
    "ID": "MEDIA095",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Dépistage de polluants dans les cheveux : notre groupe lance l’alerte",
    "TITLE-EN": "Screening for pollutants in hair: our group sounds the alarm",
    "DESC-FR": "Vingt-six sénatrices et sénateurs du groupe Socialiste, Écologiste et Républicain (SER) ont participé à un dépistage de polluants présents dans leurs cheveux et souhaitent alerter sur l'omniprésence de substances nocives dans notre environnement",
    "DESC-EN": "Twenty-six senators from the Socialiste, Écologiste et Républicain (SER) group have taken part in a screening of the pollutants in their hair, to draw attention to the omnipresence of harmful substances in our environment.",
    "DATE": "28/06/2023",
    "SOURCE": "Sénateurs Socialistes",
    "LINK": "https://www.senateurs-socialistes.fr/actualites/depistage-de-polluants-dans-les-cheveux-notre-groupe-lance-lalerte/"
  },
  {
    "ID": "MEDIA002",
    "CAT": "Environnement",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Jusqu'à 261 polluants chimiques dans les cheveux de nos enfants",
    "TITLE-EN": "Up to 261 chemical pollutants in our children's hair",
    "DESC-FR": "Test Achats a fait analyser les mèches de cheveux d'une centaine de petits Belges âgés de 3 à 15 ans pour traquer la présence de quelque 1 800 polluants organiques.",
    "DESC-EN": "Test Achats analysed locks of hair from around a hundred Belgian children aged between 3 and 15 to track down the presence of some 1,800 organic pollutants.",
    "DATE": "25/11/2021",
    "SOURCE": "Tests Achats",
    "LINK": "https://www.test-achats.be/sante/alimentation-et-nutrition/securite-alimentaire-et-additifs/presse/2021/biomonitoring#:~:text=Quelque%20261%20polluants%20ont%20%C3%A9t%C3%A9,certains%20en%20comptaient%20bien%20davantage."
  },
  {
    "ID": "MEDIA070",
    "CAT": "Sénat",
    "FEATURED": true,
    "TYPE": "TV",
    "TITLE-FR": "Mercure, pesticides, plastifiants et \"terres rares\" retrouvés dans les cheveux de sénateurs socialistes",
    "TITLE-EN": "Mercury, pesticides, plasticisers and \"rare earths\" found in the hair of Socialist senators",
    "DESC-FR": "Des analyses toxicologiques ont été menées sur 26 sénateurs et sénatrices socialistes volontaires, d'après des échantillons de leurs cheveux.",
    "DESC-EN": "Des analyses toxicologiques ont été menées sur 26 sénateurs et sénatrices socialistes volontaires, d'après des échantillons de leurs cheveux.",
    "DATE": "27/06/2023",
    "SOURCE": "TF1 Info",
    "LINK": "https://www.tf1info.fr/politique/mercure-pesticides-plastifiants-et-terres-rares-retrouves-dans-les-cheveux-de-senateurs-socialistes-2261796.html"
  },
  {
    "ID": "MEDIA055",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "TV",
    "TITLE-FR": "Cancers pédiatriques dans l'Eure : pourquoi tant de cas inexpliqués ?",
    "TITLE-EN": "Paediatric cancers in the Eure: why so many unexplained cases?",
    "DESC-FR": "Dans l'Eure, des mères de famille tentent de faire la lumière sur un nombre anormalement élevé de cas de\ncancers pédiatriques.",
    "DESC-EN": "In the Eure region of France, mothers are trying to shed light on an abnormally high number of cases of paediatric cancer. paediatric cancers.",
    "DATE": "13/06/2022",
    "SOURCE": "TF1 Info",
    "LINK": "https://www.tf1info.fr/sante/video-cancers-pediatriques-pourquoi-tant-de-cas-inexpliques-dans-l-eure-2222891.html"
  },
  {
    "ID": "MEDIA093",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercure, pesticides, plastifiants,\"terres rares\"... ils sont tous présents dans les cheveux des sénateurs français selon une étude",
    "TITLE-EN": "Mercury, pesticides, plasticisers, \"rare earths\"... they are all present in the hair of French senators, according to a study.",
    "DESC-FR": "Que trouve-t-on dans les cheveux des sénateurs français ? Du mercure, des pesticides, des plastifiants, mais aussi des \"terres rares\", ces métaux utilisés dans les smartphones et autres objets de haute technologie, révèle une analyse conduite chez 26 élus socialistes.",
    "DESC-EN": "What can be found in the hair of French senators? Mercury, pesticides, plasticisers, but also \"rare earths\", the metals used in smartphones and other high-tech objects, reveals an analysis carried out on 26 Socialist elected representatives.",
    "DATE": "27/06/2023",
    "SOURCE": "What's up Doc ?",
    "LINK": "https://www.whatsupdoc-lemag.fr/article/mercure-pesticides-plastifiantsterres-rares-ils-sont-tous-presents-dans-les-cheveux-des"
  },
  {
    "ID": "MEDIA097",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Un cocktail toxique dépisté dans les cheveux des sénateurs français",
    "TITLE-EN": "Toxic cocktail detected in the hair of French senators",
    "DESC-FR": "Que trouve-t-on dans les cheveux des sénateurs français? Du mercure, des pesticides, des plastifiants, mais aussi des «terres rares», ces métaux utilisés dans les smartphones et autres objets de haute technologie, révèle une ",
    "DESC-EN": "What can be found in the hair of French senators? Mercury, pesticides, plasticizers, but also \"rare earths\", the metals used in smartphones and other high-tech objects, reveals a new study.",
    "DATE": "27/06/2023",
    "SOURCE": "Le Journal de Montreal",
    "LINK": "https://www.journaldemontreal.com/2023/06/27/un-cocktail-toxique-depiste-dans-les-cheveux-des-senateurs-francais"
  },
  {
    "ID": "MEDIA098",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercury, Pesticides, Plasticizers: A Toxic Cocktail Detected In The Hair Of Senators",
    "TITLE-EN": "Mercury, Pesticides, Plasticizers: A Toxic Cocktail Detected In The Hair Of Senators",
    "DESC-FR": "What’s in the hair of senators? Mercury, pesticides, plasticizers, but also rare earths. This is revealed by an analysis conducted by the socialist group among twenty-six of its elected volunteers, including their president, Patrick Kanner. In July 2022, the latter entrusted a lock of their hair to the private and independent laboratory toxSeek, which screened for 1,800 organic pollutants and 49 metals. The results were released Tuesday, June 27.",
    "DESC-EN": "What’s in the hair of senators? Mercury, pesticides, plasticizers, but also rare earths. This is revealed by an analysis conducted by the socialist group among twenty-six of its elected volunteers, including their president, Patrick Kanner. In July 2022, the latter entrusted a lock of their hair to the private and independent laboratory toxSeek, which screened for 1,800 organic pollutants and 49 metals. The results were released Tuesday, June 27.",
    "DATE": "27/06/2023",
    "SOURCE": "Globe World News Echo",
    "LINK": "https://globeecho.com/news/europe/france/mercury-pesticides-plasticizers-a-toxic-cocktail-detected-in-the-hair-of-senators/"
  },
  {
    "ID": "MEDIA099",
    "CAT": "Sénat",
    "FEATURED": true,
    "TYPE": "Presse",
    "TITLE-FR": "Hairy Findings: Chemicals Study Jolts French Senators",
    "TITLE-EN": "Hairy Findings: Chemicals Study Jolts French Senators",
    "DESC-FR": "Mercury, pesticides and rare earth metals form a toxic cocktail that a French laboratory announced Tuesday it has found in an unlikely place: on the heads of senators.",
    "DESC-EN": "Mercury, pesticides and rare earth metals form a toxic cocktail that a French laboratory announced Tuesday it has found in an unlikely place: on the heads of senators.",
    "DATE": "27/06/2023",
    "SOURCE": "Barron's",
    "LINK": "https://www.barrons.com/news/hairy-findings-chemicals-study-jolts-french-senators-208cd08a"
  },
  {
    "ID": "MEDIA100",
    "CAT": "Sénat",
    "FEATURED": true,
    "TYPE": "Presse",
    "TITLE-FR": "Hairy findings: chemicals study jolts French senators",
    "TITLE-EN": "Hairy findings: chemicals study jolts French senators",
    "DESC-FR": "Mercury, pesticides and rare earth metals form a toxic cocktail that a French laboratory announced Tuesday it has found in an unlikely place: on the heads of senators.",
    "DESC-EN": "Mercury, pesticides and rare earth metals form a toxic cocktail that a French laboratory announced Tuesday it has found in an unlikely place: on the heads of senators.",
    "DATE": "27/06/2023",
    "SOURCE": "Medical X Press",
    "LINK": "https://medicalxpress.com/news/2023-06-hairy-chemicals-jolts-french-senators.html"
  },
  {
    "ID": "MEDIA101",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Mercury, pesticides and “rare earths”: a toxic cocktail detected in the hair of French senators | World",
    "TITLE-EN": "Mercury, pesticides and \"rare earths\": a toxic cocktail detected in the hair of French senators | World",
    "DESC-FR": "In July 2022, they entrusted a lock of their hair to the private and independent laboratory toxSeek, which screened for 1,800 organic pollutants and 49 metals. The results were released on Tuesday.",
    "DESC-EN": "In July 2022, they entrusted a lock of their hair to the private and independent laboratory toxSeek, which screened for 1,800 organic pollutants and 49 metals. The results were released on Tuesday.",
    "DATE": "27/06/2023",
    "SOURCE": "DayFReuro",
    "LINK": "https://www.google.com/url?q=https://euro.dayfr.com/world/456476.html&sa=D&source=docs&ust=1689095163229077&usg=AOvVaw36mOxDkp6bk4FkRsUZ_NT5"
  },
  {
    "ID": "MEDIA102",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "A toxic tail detected in the hair of the senators",
    "TITLE-EN": "A toxic tail detected in the hair of the senators",
    "DESC-FR": "What’s in the hair of senators? Mercury, pesticides, plasticizers, but also rare earths. This is revealed by an analysis conducted by the socialist group among twenty-six of its elected volunteers, including their president, Patrick Kanner.",
    "DESC-EN": "What’s in the hair of senators? Mercury, pesticides, plasticizers, but also rare earths. This is revealed by an analysis conducted by the socialist group among twenty-six of its elected volunteers, including their president, Patrick Kanner.",
    "DATE": "27/06/2023",
    "SOURCE": "News In France",
    "LINK": "https://newsinfrance.com/a-toxic-cocktail-detected-in-the-hair-of-the-senators/"
  },
  {
    "ID": "MEDIA103",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Parlamentarier beunruhigt: Französische Senatoren mit Schadstoffen und Metallen belastet",
    "TITLE-EN": "Parliamentarians worried: French senators contaminated with pollutants and metals",
    "DESC-FR": "Quecksilber, Pestizide, Weichmacher, aber auch \"seltene Erden\", also Metalle, die vor allem in Smartphones verwendet wer...",
    "DESC-EN": "Mercury, pesticides, plasticisers, but also \"rare earths\", i.e. metals that are mainly used in smartphones....",
    "DATE": "28/06/2023",
    "SOURCE": "Nachrichten",
    "LINK": "https://nachrichten.fr/parlamentarier-beunruhigt-franzoesische-senatoren-mit-schadstoffen-und-metallen-belastet/"
  },
  {
    "ID": "MEDIA104",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Toxic cocktail of mercury, pesticides and rare earth metals found in heads of French senators",
    "TITLE-EN": "Toxic cocktail of mercury, pesticides and rare earth metals found in heads of French senators",
    "DESC-FR": "A French laboratory has made an incredible discovery in the hair of some of the country's top politicians.",
    "DESC-EN": "A French laboratory has made an incredible discovery in the hair of some of the country's top politicians.",
    "DATE": "29/06/2023",
    "SOURCE": "News.eu",
    "LINK": "https://newseu.cgtn.com/news/2023-06-29/Toxic-cocktail-found-on-French-senators-heads-1l0OPxGXdUk/index.html"
  },
  {
    "ID": "MEDIA105",
    "CAT": "Sénat",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "French Lawmakers Learn They're 'Contaminated'",
    "TITLE-EN": "French Lawmakers Learn They're 'Contaminated",
    "DESC-FR": "PARIS — Mercury, pesticides and rare earth metals form a toxic cocktail that a French laboratory announced Tuesday it has found in an unlikely place: on the heads of Paris lawmakers.",
    "DESC-EN": "PARIS — Mercury, pesticides and rare earth metals form a toxic cocktail that a French laboratory announced Tuesday it has found in an unlikely place: on the heads of Paris lawmakers.",
    "DATE": "01/07/2023",
    "SOURCE": "Voa Africa",
    "LINK": "https://www.voaafrica.com/a/french-lawmakers-learn-they-re-contaminated-/7154811.html"
  },
  {
    "ID": "MEDIA106",
    "CAT": "Santé",
    "FEATURED": true,
    "TYPE": "Presse",
    "TITLE-FR": "Une ado de 13 ans meurt d'une tumeur au sein : \"Je veux la vérité\", Shiloh a-t-elle été empoisonnée par des métaux lourds ?",
    "TITLE-EN": "13-year-old dies of breast tumour: \"I want the truth\", was Shiloh poisoned by heavy metals?",
    "DESC-FR": "Une jeune fille de 13 ans est décédée en décembre 2021 d'un angiosarcome mammaire. Ses parents suspectent son environnement, son école maternelle notamment, d'être en partie responsables et vont donc déposer plainte contre X auprès du procureur de Pontoise.",
    "DESC-EN": "A 13-year-old girl died in December 2021 from a mammary angiosarcoma. Her parents suspect that her environment, and in particular her nursery school, were partly responsible, and will therefore file a complaint against X with the Pontoise public prosecutor.",
    "DATE": "23/05/2023",
    "SOURCE": "Midi Libre",
    "LINK": "https://www.midilibre.fr/2023/05/23/une-ado-13-ans-meurt-dune-tumeur-au-sein-je-veux-la-verite-shiloh-a-t-elle-ete-empoisonnee-par-des-metaux-lourds-11213399.php"
  },
  {
    "ID": "MEDIA107",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Le combat d'une mère",
    "TITLE-EN": "One mother's battle",
    "DESC-FR": "Shiloh est décédée en 2021 à 13 ans d'une tumeur au sein. Des analyses révèlent qu'elle a été exposée à des éléments toxiques dans son environnement valdoisien, un risque qui nous concerne tous. L’édito de Laurence Allezy, rédactrice en chef du Parisien-Aujourd'hui en France en charge des éditions départementales.",
    "DESC-EN": "Shiloh died in 2021 at the age of 13 from a breast tumour. Analyses reveal that she was exposed to toxic elements in her environment in the Valdois region, a risk that concerns us all. Editorial by Laurence Allezy, editor-in-chief of Le Parisien-Aujourd'hui en France, responsible for the departmental editions.",
    "DATE": "18/05/2023",
    "SOURCE": "Le Parisien",
    "LINK": "https://www.leparisien.fr/societe/sante/le-combat-dune-mere-18-05-2023-KA2US6OXWBFQHCRWRFMTCXVQCY.php"
  },
  {
    "ID": "MEDIA108",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Radio",
    "TITLE-FR": "Cancers pédiatriques : l'environnement en question",
    "TITLE-EN": "Paediatric cancers: the environment in question",
    "DESC-FR": "Dans plusieurs communes de France, des familles alertent les autorités de santé : leurs enfants développent des cancers. Des amis de leurs enfants aussi. De la Charente-Maritime à la Loire-Atlantique, les parents, organisés en collectifs, réclament des études environnementales et des réponses à leurs questions.",
    "DESC-EN": "In several French towns, families are alerting the health authorities that their children are developing cancer. Friends of their children too. From Charente-Maritime to Loire-Atlantique, the parents, organised into groups, are demanding environmental studies and answers to their questions.",
    "DATE": "06/12/2020",
    "SOURCE": "France Inter",
    "LINK": "https://www.radiofrance.fr/franceinter/podcasts/interception/cancers-pediatriques-l-environnement-en-question-4942691"
  },
  {
    "ID": "MEDIA109",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "Presse",
    "TITLE-FR": "Enquête Aucune explication aux clusters de cancers d'enfants : l'échec des autorités de santé ?",
    "TITLE-EN": "Enquête No explanation for clusters of childhood cancers: a failure on the part of the health authorities?",
    "DESC-FR": "Trois clusters de cancers d'enfants sont répertoriés en France. Peut-être un quatrième à venir. À chaque fois, les autorités sanitaires n'ont aucune explication à donner.",
    "DESC-EN": "Three childhood cancer clusters have been identified in France. A fourth may be on the way. Each time, the health authorities have no explanation to offer.",
    "DATE": "24/11/2021",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/societe/enquete-aucune-explication-aux-clusters-de-cancers-d-enfants-l-echec-des-autorites-de-sante_46004714.html"
  },
  {
    "ID": "MEDIA110",
    "CAT": "Santé",
    "FEATURED": true,
    "TYPE": "TV",
    "TITLE-FR": "Cancer pédiatriques à Pont de l'Arche ",
    "TITLE-EN": "Paediatric cancer in Pont de l'Arche",
    "DESC-FR": "Reportage journal télévisé, 13H TF1 sur les cancers pédiatriques à Pont de l'Arche, France",
    "DESC-EN": "TV news report, 13H TF1 on paediatric cancers in Pont de l'Arche, France",
    "DATE": "13/06/2022",
    "SOURCE": "TF1 13H",
    "LINK": "https://vimeo.com/847312416?share=copy"
  },
  {
    "ID": "MEDIA111",
    "CAT": "Environnement",
    "FEATURED": true,
    "TYPE": "TV",
    "TITLE-FR": "Reportage du journal télévisé de France 3 - Voisinage empoisonné ?",
    "TITLE-EN": "France 3 TV news report - Poisoned neighbours?",
    "DESC-FR": "Reportage du journal télévisé de France 3 au sujet de riverains contaminés par des pesticides car ils habitent prés de culture",
    "DESC-EN": "France 3 TV news report about residents contaminated by pesticides because they live near crops",
    "DATE": "02/10/2019",
    "SOURCE": "France 3",
    "LINK": "https://www.youtube.com/watch?v=mmD_GIAlZLk"
  },
  {
    "ID": "MEDIA112",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "TV",
    "TITLE-FR": "Cancers pédiatrique Sainte Pazanne",
    "TITLE-EN": "Paediatric cancer Sainte Pazanne",
    "DESC-FR": "Reportage télévision pour TV Nantes au sujet du cluster de cancer pédiatrique de SAinte-Pazanne",
    "DESC-EN": "Television report for TV Nantes on the SAinte-Pazanne paediatric cancer cluster",
    "DATE": "01/10/2019",
    "SOURCE": "TV Nantes",
    "LINK": "Marie Thibaud à propos des cancers pédiatriques en Loire-Atlantique par LeCourrierduPaysdeRetz (soundcloud.com)"
  },
  {
    "ID": "MEDIA113",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "TV",
    "TITLE-FR": "Cancers pédiatrique Sainte Pazanne",
    "TITLE-EN": "Paediatric cancer Sainte Pazanne",
    "DESC-FR": "Interview de Marie Thiebault, du collectif  collectif \"Stop aux cancers de nos enfants\"",
    "DESC-EN": "Interview with Marie Thiebault, from the \"Stop our children's cancers\" collective",
    "DATE": "01/10/2019",
    "SOURCE": "Kombini",
    "LINK": "https://www.youtube.com/watch?v=B1mmKzqH4ew"
  },
  {
    "ID": "MEDIA114",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "TV",
    "TITLE-FR": "Cluster anormal de cancers d'enfants dans l'Eure : des tests effectués par les parents",
    "TITLE-EN": "Abnormal cluster of childhood cancers in Eure: tests carried out by parents",
    "DESC-FR": "Interview de Coralie Jarguel, maman d'un enfant atteint par un cancer pédiatrique à l'âge de 10 ans",
    "DESC-EN": "Interview with Coralie Jarguel, mother of a child diagnosed with paediatric cancer at the age of 10",
    "DATE": "20/03/2022",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/normandie/pont-de-l-arche_27469/video-cluster-anormal-de-cancers-d-enfants-dans-l-eure-des-tests-effectues-par-les-parents_49551103.html"
  },
  {
    "ID": "MEDIA115",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "TV",
    "TITLE-FR": "Cancers pédiatriques en Loire-Atlantique : un enfant de 4 ans atteint de leucémie, à Machecoul-Saint-Même",
    "TITLE-EN": "Abnormal cluster of childhood cancers in Eure: tests carried out by parents",
    "DESC-FR": "Interview de Marie Thiebault",
    "DESC-EN": "Interview of Marie Thiebault",
    "DATE": "16/09/2020",
    "SOURCE": "Actu",
    "LINK": "https://actu.fr/pays-de-la-loire/machecoul-saint-meme_44087/cancers-pediatriques-en-loire-atlantique-un-enfant-de-4-ans-atteint-de-leucemie-a-machecoul-saint-meme_36166763.html"
  },
  {
    "ID": "MEDIA116",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "TV",
    "TITLE-FR": "Cancers pédiatriques en Loire-Atlantique : Matthieu Davoli (toxSeek) sur les analyses de cheveux",
    "TITLE-EN": "Paediatric cancers in Loire-Atlantique: Matthieu Davoli (ToxSeek) on hair analysis",
    "DESC-FR": "Paediatric cancers in Loire-Atlantique: Matthieu Davoli (ToxSeek) on hair analysis",
    "DESC-EN": "Paediatric cancers in Loire-Atlantique: Matthieu Davoli (ToxSeek) on hair analysis",
    "DATE": "30/09/2019",
    "SOURCE": "Actu",
    "LINK": "Cancers pédiatriques en Loire-Atlantique : Matthieu Davoli (toxSeek) sur les analyses de cheveux - YouTube"
  },
  {
    "ID": "MEDIA117",
    "CAT": "Santé",
    "FEATURED": false,
    "TYPE": "TV",
    "TITLE-FR": "Cancers à Sainte-Pazanne : des traces de métaux dans les cheveux des enfants",
    "TITLE-EN": "Cancer in Sainte-Pazanne: traces of metals in children's hair",
    "DESC-FR": "Interview de Marie Thiebault, collectif \"Stop aux cancers de nos enfants\" suite aux cancers pédiatriques à Sainte Pazanne",
    "DESC-EN": "Interview with Marie Thiebault, \"Stop cancers in our children\" group, following paediatric cancers in Sainte Pazanne",
    "DATE": "01/10/2019",
    "SOURCE": "C News",
    "LINK": "https://www.cnews.fr/videos/france/2019-10-02/cancers-sainte-pazanne-des-traces-de-metaux-dans-les-cheveux-des-enfants"
  },
  {
    "ID": "MEDIA118",
    "CAT": "Santé",
    "FEATURED": true,
    "TYPE": "TV",
    "TITLE-FR": "Cancer pédiatriques Sainte-Pazanne",
    "TITLE-EN": "Paediatric cancer Sainte-Pazanne",
    "DESC-FR": "Reportage journal télévisé de France suite à l'analyse de cheveux d'enfants atteint de cancer",
    "DESC-EN": "Report on the French television news following the analysis of the hair of children suffering from cancer",
    "DATE": "01/10/2019",
    "SOURCE": "France 3",
    "LINK": "https://vimeo.com/844903519?share=copy"
  },
  {
    "ID": "MEDIA119",
    "CAT": "Santé",
    "FEATURED": true,
    "TYPE": "TV",
    "TITLE-FR": "Emission Envoyé Spécial France 2 - En Inde, des femmes forcées de se faire enlever l'utérus",
    "TITLE-EN": "Envoyé Spécial France 2 program - In India, women forced to have their uterus removed ",
    "DESC-FR": "De nombreuses ramasseuses de canne à sucre dans la région de Beed, dans le sud de l'Inde, ressentent des maux de ventre et subissent, pour y mettre fin, des ablations de l'utérus. Sont-elles stérilisées abusivement ? Quelle est la responsabilité de l'industrie sucrière dans leur maladie ?",
    "DESC-EN": "Many sugar cane pickers in the Beed region of southern India suffer from stomach pains and have their wombs removed to stop them. Are they being abusively sterilised? Is the sugar industry responsible for their illness?",
    "DATE": "19/05/2022",
    "SOURCE": "France 2",
    "LINK": "https://vimeo.com/847312517?share=copy"
  },
  {
    "ID": "MEDIA120",
    "CAT": "Sénat",
    "FEATURED": true,
    "TYPE": "Radio",
    "TITLE-FR": "Interview en direct à la radio de Matthieu Davoli suite à notre action au Sénat français",
    "TITLE-EN": "Live radio interview with Matthieu Davoli following his action at the French Senate",
    "DESC-FR": "Interview en direct à la radio française de Matthieu Davoli suite au test de 26 sénateurs",
    "DESC-EN": "Live interview on French radio with Matthieu Davoli following the test of 26 senators",
    "DATE": "26/06/2023",
    "SOURCE": "France Info",
    "LINK": "https://vimeo.com/841125034?share=copy"
  }
]

const LOGOS = {
  "20minutes": "20_Minutes_logo.svg",
  "Actu": "Actu.fr_logo_2020.svg",
  "BFMTV": "bf6689bcd3-bfm-tv-logo-bfmtv-logo-force-media.png",
  "Bien Public": "Logo_Journal_Le_Bien_Public_-_2022.svg",
  "Bon à Savoir": "Logo_Bon_à_Savoir.jpg",
  "Capital": "capital-fr-vector-logo.svg",
  "Envoyé Spécial": "Envoyé_Spécial_logo.svg",
  "Europe 1": "Europe_1_logo_(2010).svg",
  "France 3": "France_3_-_logo_2018.svg",
  "France Bleu": "france-bleu-logo-vector.svg",
  "France Info": "Franceinfo.svg",
  "France Live": "9ecebdef4fcb5ebedd7fc854d3995419-website-france-live-dark.svg",
  "France Soir": "Logo_FRANCESOIR.png",
  "France24": "pngwing.com.png",
  "Générations Futures": "Logo-generations-futures.png",
  "L'info durable": "new_logo_header.svg",
  "L'Obs": "Obs_2014_logo.svg.png",
  "L'Union": "L'Union_logo.svg",
  "La Dépêche": "La_dépêche.png",
  "La Nouvelle République": "Logo_de_La_Nouvelle_République_du_Centre-Ouest_(2008-).png",
  "Le Courrier Indépendant": "le-courrier-independant.svg",
  "Le Figaro": "Le_Figaro.svg",
  "Le Monde": "Le_monde_logo.svg",
  "Le Parisien": "Le_Parisien_logo.svg",
  "Le Point": "le-point-logo-svg-vector.svg",
  "Le Temps": "Letemps-logo-new.svg",
  "Les Echos": "les-echos-logo-svg-vector.svg",
  "Libération": "Libération.svg",
  "Marie Claire": "Marie_Claire_Magazine_logo.svg",
  "Ouest France": "Ouest-France_logo.svg",
  "Paris-Normandie": "Paris-normandie_0.png",
  "Public Sénat": "Logo_Public_Sénat_(2019).svg",
  "Reporterre": "Logo_Reporterre.svg",
  "Rue89Bordeaux": "Logo_de_Rue89_Bordeaux.png",
  "Sciences et Avenir": "Science_et_Avenir.svg",
  "Tests Achats": "Testachats-sante-logo.svg",
  "TF1 Info": "Logo_TF1Info_2023.png",
  "Egora": "logo.png",
  "Orange": "Orange_logo.svg",
  "Conso Globe": "consoglobe.png.webp",
  "La Montagne": "logo-aef-info.svg",
  "JIM": "jim-logo.png",
  "La Santé au Quotidien": "logo_sante-300x80.png",
  "La Voix du Nord": "LVDNgrandlogo.png",
  "Telegramme": "640ef86af4b4a977fe0daa5b.svg",
  "L'Info.re": "info-logo.png",
  "RMC": "Logo_RMC_2002.svg",
  "Courrier Picard": "Courrier-picard80.jpg",
  "ArabNews": "45-logo-mobile-new.png",
  "Le Creusot Infos": "21812_1_full.jpg",
  "24Matins": "24matins_uk-logo-normal-250618.png",
  "La Provence": "La_Provence_(logo).svg",
  "What's up Doc ?": "logo-desktop.png",
  "Sénateurs Socialistes": "Logo-GroupeSER.png",
  "Gazette": "logo (1).svg",
  "Contrepoints": "logo_cp_white.png",
  "Le Journal de Montreal": "45917ec6-b1ae-4125-8b2d-e802a85baf5c_jdm-logo-default-white.svg",
  "Globe World News Echo": "globe-echo-logo-sm.png",
  "Barron's": "download.svg",
  "Medical X Press": "logo (1).png",
  "DayFReuro": "logo (2).png",
  "News In France": "cropped-image-2022-02-06T094724.734.png",
  "Nachrichten": "Nachrichten-300x55.jpg",
  "News.eu": "img_logo.png",
  "Voa Africa": "logo.svg",
  "Midi Libre": "Logo_midi_libre.png",
  "France Inter": "France_Inter_logo_2021.svg",
  "TF1 13H": "TF1_logo_2013.png",
  "TV Nantes": "TELENANTES_2020.png",
  "Kombini": "konbini-logo-vector.svg",
  "C News": "Canal_News_logo.svg",
  "France 2": "France_2_2018.svg",
  "Radio France": "Radio_France_logo.png",
  "CEA Digitellinc": "E46E656C-7A92-435A-8260-3EC3ED3F8C4E.png"
}

module.exports = { DATA, LOGOS };