<template>
  <nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#"><img :src="require('@/assets/Logos/toxseek_logo.png')" /></a> <span
        style="font-size: 20px; color: #004494">{{ $t("title") }}</span>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li v-for="c in categories" :key="c" class="nav-item">
            <a class="nav-link" :style="'color:' + dc(c)" :href="'#' + c">{{ $t(c) }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style="color: #004494; cursor: pointer;" @click="switchLocale">{{ $t("switch") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  props: ["categories", "dc"],
  methods: {
    switchLocale() {
      if (this.$i18n.locale == "fr") this.$i18n.locale = 'en';
      else this.$i18n.locale = "fr";
      window.location.href = "/" + this.$i18n.locale;
    }
  }
}
</script>

<style scoped>
nav {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.073), 0px 2px 10px rgba(0, 0, 0, 0.095);
}

.bg-light {
  background: white !important;
}

img {
  max-height: 30px;
}

a {
  opacity: 0.8;
  font-weight: 600;
}

a:hover {
  opacity: 1;
  transition: all ease-in-out 0.2s;
}
</style>