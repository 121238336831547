<template>
  <div id="app">
    <Nav :categories="Object.keys(DATA)" :dc="defineColour" />
    <b-container>
      <b-row>
        <div class="col-12 py-5 px-5 p-md-0" v-for="c in Object.keys(DATA)" :key="c" :id="c">
          <h2 class="pt-5 pb-3" :style="'color:' + defineColour(c)">{{ $t(c) }}</h2>
          <b-row class="gx-5 gy-4 pb-5">
            <div v-for="(d, i) in DATA[c]" :class="'col-12 col-md-' + (i < 4 ? 6 : 6) + ' col-lg-' + (i < 4 ? 3 : 4)"
              :key="d.ID">
              <ArticleCard v-if="i < 4" :title="d['TITLE-' + $i18n.locale.toUpperCase()]"
                :desc="d['DESC-' + $i18n.locale.toUpperCase()]" :img="d.ID + '.jpg'" :link="d.LINK" :cat="d.CAT"
                :source="d.SOURCE" :date="d.DATE" :colour="defineColour()" />
              <SmallArticleCard v-else :title="d['TITLE-' + $i18n.locale.toUpperCase()]"
                :desc="d['DESC-' + $i18n.locale.toUpperCase()]" :img="d.ID + '.jpg'" :link="d.LINK" :cat="d.CAT"
                :source="d.SOURCE" :date="d.DATE" :colour="defineColour()" />
            </div>

          </b-row>
        </div>
      </b-row>
    </b-container>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import SmallArticleCard from './components/SmallArticleCard.vue';
import ArticleCard from './components/ArticleCard.vue';
import DATASET from './components/Dataset.js';
import Nav from './components/Nav.vue';

export default {
  name: 'App',
  components: {
    SmallArticleCard,
    ArticleCard,
    Nav
  },
  data() {
    return {
      DATASET,
      DATA: {}
    }
  },
  mounted() {
    if (window.location.pathname == "/fr") this.$i18n.locale = 'fr';
    else this.$i18n.locale = 'en';

    document.title = this.$t("page_title");

    this.DATA = { "À la une": [], "Environnement": [], "Santé": [], "Sénat": [] };
    this.DATASET.DATA.forEach(d => {
      // if (!(d.CAT in this.DATA)) this.DATA[d.CAT] = [];
      this.DATA[d.CAT].push(d);
      if (d.FEATURED) this.DATA["À la une"].push(d);
    });

    for (var i = 0; i < Object.keys(this.DATA).length; ++i) {
      this.DATA[Object.keys(this.DATA)[i]].sort(function (a, b) {
        let ad = a.DATE.split("/");
        let aDate = new Date(ad[2] + '/' + ad[1] + '/' + ad[0]);
        let bd = b.DATE.split("/");
        let bDate = new Date(bd[2] + '/' + bd[1] + '/' + bd[0]);
        return bDate - aDate;
      });

    }
  },
  methods: {
    defineColour() {
      return "#004494";
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-family: 'Poppins', sans-serif;
}

h2 {
  font-size: 30px !important;
  text-align: left;
  font-weight: 600 !important;
}
</style>
