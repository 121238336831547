<template>
  <b-row class="border-card h-100">
    <a class="col-12 p-0 img-link" :style="'border-color:' + colour" :href="link" target="_blank">
      <img class="background" :src="require('@/assets/MEDIAS/' + img)" />
      <div class="logo">
        <img :src="require('@/assets/Logos/' + DATASET.LOGOS[source])" />
      </div>
    </a>

    <div class="col-12">
      <h1 class="p-3 pb-0">
        {{ trim(title, 80) }}
      </h1>
      <h5 class="pb-1">{{ date }}</h5>
    </div>

    <div class="col-3"></div>
    <div class="col-6">
      <hr class="m-0" />
    </div>

    <div class="col-12">
      <p class="p-3 pt-3 pb-0">
        {{ trim(desc, 175) }}
      </p>
    </div>

    <div class="col-12 pb-4">
      <a class="btn" :style="'text-transform: uppercase; background:' + colour" :href="link"
        target="_blank">{{ $t("more") }}</a>
    </div>
  </b-row>
</template>

<script>
import DATASET from './Dataset.js';

export default {
  name: "ArticleCard",
  props: ["title", "desc", "link", "img", "cat", "source", "date", "colour"],
  data() {
    return {
      DATASET
    }
  },
  methods: {
    trim(content, max) {
      content = content.trim();
      if (content.length > max)
        content = content.slice(0, max - 3) + "...";
      else content = content.padEnd(max, ' ');
      return content;
    }
  }
}
</script>


<style scoped>
.border-card {
  border-radius: 4px;
  box-shadow: 6px 4px 3px rgba(0, 0, 0, 0.062), 3px 0px 10px rgba(0, 0, 0, 0.111);
  border-radius: 6px;
  overflow: hidden;
}

.background {
  width: 100%;
  height: 200px;
  object-fit: cover;
  padding: 0;
}

.logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 200px;
  background: #000000af;
  transition: all ease-in-out .2s;
}

.logo:hover {
  background: #232323a1;
  cursor: pointer;
}

.logo:hover img {
  width: 65%;
  opacity: 1;
}

.logo>img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  transition: all ease-in-out .2s;
  opacity: 0.9;
}


.img-link {
  position: relative;
  height: 200px;
  overflow: hidden;
  border-bottom: solid 3px red;
}

h1 {
  font-size: 15px;
  font-weight: 700;
}

h5 {
  font-size: 12px;
  font-weight: 300;
  color: grey;
}

p {
  font-size: 12px;
  color: gray;
  font-weight: 300;
}

a,
a:hover {
  text-decoration: none;
  color: white;
}

.btn {
  background: rgb(58, 70, 181);
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 12px;
  transition: all ease-in-out .2s;
  opacity: 0.8;
}

.btn:hover {
  opacity: 1;
}

hr {
  border-top: grey solid 1px;
}
</style>
